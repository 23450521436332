<template>
  <Layout :title="text.page_title">
    <div class="container">
      <FormBlock :label="text.sentence">
        <FormSelect
          v-model="language"
          :options="text.language_options"
          :block="true"
          @change="changeLanguage"
        />
      </FormBlock>
      <ButtonBlock layout="center">
        <Button @click="updateLanguage">{{ text.setting_button }}</Button>
      </ButtonBlock>
    </div>
  </Layout>
</template>

<script>
import {
  getSettingsLanguageApi,
  postSettingsLanguageApi,
  settingsListApi,
} from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import FormBlock from "@/components/form/FormBlock";
import FormSelect from "@/components/form/FormSelect";
import ButtonBlock from "@/components/button/ButtonBlock";
import Button from "@/components/button/Button";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W070",
  components: {
    Layout,
    FormBlock,
    FormSelect,
    ButtonBlock,
    Button,
  },
  mixins: [ApiMixin],
  data() {
    return {
      language: "",
      text: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "setDrawerMenu"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        getSettingsLanguageApi().then((res) => {
          this.language = res.data.item.language;
          this.text = res.data.text;
          this.stopLoading();
        });
      });
    },
    changeLanguage() {
      if (this.language === "ja") {
        sendEvent(SCREEN_ID.Y1W070, ACTION.Y1W070.SELECT_LANGUAGE_JA);
      } else if (this.language === "en") {
        sendEvent(SCREEN_ID.Y1W070, ACTION.Y1W070.SELECT_LANGUAGE_EN);
      } else if (this.language === "id") {
        sendEvent(SCREEN_ID.Y1W070, ACTION.Y1W070.SELECT_LANGUAGE_ID);
      } else if (this.language === "ms") {
        sendEvent(SCREEN_ID.Y1W070, ACTION.Y1W070.SELECT_LANGUAGE_MS);
      } else if (this.language === "zh-tw") {
        sendEvent(SCREEN_ID.Y1W070, ACTION.Y1W070.SELECT_LANGUAGE_ZH);
      } else if (this.language === "th") {
        sendEvent(SCREEN_ID.Y1W070, ACTION.Y1W070.SELECT_LANGUAGE_TH);
      }
    },
    updateLanguage() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        postSettingsLanguageApi({
          language: this.language,
        }).then(() => {
          this.$_verifyGigyaLogin(() => {
            getSettingsLanguageApi().then((res) => {
              this.language = res.data.item.language;
              this.text = res.data.text;
              this.stopLoading();
            });
          });
          this.$_verifyGigyaLogin(() => {
            settingsListApi().then((res) => {
              this.setDrawerMenu({ drawerMenu: res.data.text });
            });
          });
        });
      });
      sendEvent(SCREEN_ID.Y1W070, ACTION.Y1W070.CLICK_BUTTON_SETTING);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: $spacing-md;
  > .button-block {
    margin-top: $spacing-md;
  }
}
</style>
