<template>
  <div class="progress-circular">
    <div v-if="!progressDone" class="progress-circular__loader"></div>
    <IconLinkageDone v-if="progressDone" :size="56" />
  </div>
</template>

<script>
import IconLinkageDone from "@/components/icon/IconLinkageDone";

export default {
  name: "LinkageProgressCircular",
  components: {
    IconLinkageDone,
  },
  props: {
    progressDone: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-circular {
  display: flex;
  align-items: center;
  justify-content: center;

  &__loader {
    width: 56px;
    height: 56px;
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 6px solid $gray300;
    border-right: 6px solid $gray300;
    border-bottom: 6px solid $gray300;
    border-left: 6px solid $yellow300;
    animation: load 1.1s infinite linear;
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
