<template>
  <label class="form-select-label" :class="{ 'w-100': block }">
    <select
      v-model="localValue"
      class="form-select"
      :class="[
        {
          'is-disabled': disabled,
          'is-error': isError,
        },
      ]"
      :disabled="disabled"
      @change="updateValue"
    >
      <option v-for="(option, i) in options" :key="i" :value="option.id">
        {{ option.label }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  name: "FormSelect",
  props: {
    value: {
      type: [String, Array],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    updateValue: function (e) {
      this.$emit("change", e.target.value);
    },
  },
};
</script>

<style lang="scss">
.form-select-label {
  display: inline-block;
  position: relative;
  width: 156px;

  &.w-100 {
    width: 100%;
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $spacing-xxs;
    width: 10px;
    height: 5px;
    background: url(../../assets/images/selectArror.svg) 0 0 no-repeat;
    pointer-events: none;
  }
}

.form-select {
  display: block;
  width: 100%;
  flex-grow: 1;
  height: $input-height;
  padding: 0 $spacing-xxs;
  background: $white100;
  box-sizing: border-box;
  border: 1px solid $gray400;
  outline: none;
  border-radius: $radius;
  color: $black400;
  font: $sans-normal-16;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: $black100;
  }

  &.is-error {
    color: $red300;
    background: $red100;
    border-color: $red100;
  }

  &.is-disabled {
    background: $gray400;
    border-color: $gray400;
    color: $black200;
    -webkit-text-fill-color: $black200;
    -webkit-opacity: 1;
  }
}
</style>
