<template>
  <div class="form-label"><slot /></div>
</template>

<script>
export default {
  name: "FormLabel",
};
</script>

<style scoped lang="scss">
.form-label {
  color: $white100;
  font: $sans-none-16-bold;
  word-wrap: break-word;
}
</style>
