<template>
  <div v-if="isShowDialog" class="dialog">
    <div class="dialog__container">
      <p class="dialog__title">{{ title }}</p>
      <div class="dialog-body">
        <p v-if="showText" class="dialog-text">
          <slot></slot>
        </p>
        <ButtonBlock class="dialog-button">
          <Button
            v-if="showButton"
            type="cancel"
            size="small"
            @click="onCancel"
          >
            CANCEL
          </Button>
          <Button type="normal" size="small" color="black" @click="onConfirm">
            OK
          </Button>
        </ButtonBlock>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button";
import ButtonBlock from "@/components/button/ButtonBlock";

export default {
  name: "Dialog",
  components: {
    Button,
    ButtonBlock,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    showText: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowDialog: false,
    };
  },
  methods: {
    openDialog() {
      this.isShowDialog = true;
    },
    hideDialog() {
      this.isShowDialog = false;
    },
    onCancel() {
      this.$emit("cancel");
      this.hideDialog();
    },
    onConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black900, $opacity);
  z-index: $dialog-z-index;

  &__container {
    padding: $spacing-sm;
    max-width: 280px;
    width: 100%;
    box-sizing: border-box;
    border-radius: $radius;
    background: $white100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    box-shadow: 0 $spacing-xxxs $spacing-xs rgba($black900, $opacity);
  }

  &__title {
    font: $sans-tight-16;
    color: $black900;
    margin-bottom: $spacing-xs;
    word-wrap: break-word;
  }

  &-text {
    font: $sans-tight-12;
    margin-bottom: $spacing-xs;
    word-wrap: break-word;
  }
}
</style>
