<template>
  <div class="wrap">
    <svg
      width="21"
      height="31"
      viewBox="0 0 21 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :class="{ focus: isFocus }"
        d="M10.235 0C4.57651 0 0 4.57651 0 10.235C0 17.9113 8.5 19.5 10.235 30.2429C11.5 19.5 20.47 17.9113 20.47 10.235C20.47 4.57651 15.8935 0 10.235 0ZM10.235 13.8904C8.21724 13.8904 6.57964 12.2528 6.57964 10.235C6.57964 8.21725 8.21724 6.57964 10.235 6.57964C12.2528 6.57964 13.8904 8.21725 13.8904 10.235C13.8904 12.2528 12.2528 13.8904 10.235 13.8904Z"
        fill="#9B9B9B"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconLocationNav",
  props: {
    isFocus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.focus {
  fill: $yellow300;
}
</style>
