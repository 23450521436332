<template>
  <div class="map-parking">
    <MapBase :language="language" :center="position" @init="init" />
    <ButtonCurrentMyLocation id="current" />
    <Loading v-if="isLoading" />
    <Dialog
      ref="dialog"
      :title="errorMessage"
      :show-button="false"
      @confirm="$refs.dialog.hideDialog()"
    />
  </div>
</template>

<script>
import MapBase from "@/components/map/MapBase";
import ButtonCurrentMyLocation from "@/components/button/ButtonCurrentMyLocation";
import Loading from "@/components/layout/Loading";
import Dialog from "@/components/dialog/Dialog";

export default {
  name: "MapParking",
  components: {
    MapBase,
    ButtonCurrentMyLocation,
    Loading,
    Dialog,
  },
  props: {
    language: {
      type: String,
      required: true,
      default: "",
    },
    position: {
      type: Object,
      required: true,
      default: () => {},
    },
    datetime: {
      type: String,
      required: true,
      default: "",
    },
    errorMessage: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      current: null,
      isLoading: false,
    };
  },
  methods: {
    init(map) {
      const bounds = new window.google.maps.LatLngBounds();

      // 駐車位置の設定
      const parking = new window.google.maps.Marker({
        position: this.position,
        icon: {
          url: require("@/assets/images/location_end.svg"),
          size: new window.google.maps.Size(56, 56),
        },
        map: map,
      });
      const infowindow = new window.google.maps.InfoWindow({
        content: this.datetime,
      });
      infowindow.open({
        anchor: parking,
        map,
        shouldFocus: false,
      });
      parking.addListener("click", () => {
        infowindow.open({
          anchor: parking,
          map,
          shouldFocus: false,
        });
      });

      // 現在位置取得ボタン
      const button = document.getElementById("current");
      button.addEventListener("click", () => {
        if (navigator.geolocation) {
          this.isLoading = true;
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.isLoading = false;
              // 前回の現在位置を削除
              if (this.current) this.current.setMap(null);
              // 現在位置を設定
              this.current = new window.google.maps.Marker({
                position: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
                icon: {
                  url: require("@/assets/images/location_current.svg"),
                  size: new window.google.maps.Size(72, 72),
                  anchor: new window.google.maps.Point(36, 36),
                },
                map: map,
              });

              // 地図表示領域をマーカー位置に合わせて拡大
              bounds.extend(parking.position);
              bounds.extend(this.current.position);
              map.fitBounds(bounds);

              // 地図中心を駐車位置に設定し、現在位置も表示する
              window.google.maps.event.addListenerOnce(
                map,
                "bounds_changed",
                function () {
                  map.setCenter(parking.position);
                  map.setZoom(map.getZoom() - 1);
                }
              );
            },
            () => {
              this.isLoading = false;
              this.$refs.dialog.openDialog();
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-parking {
  height: 100%;
  width: 100%;
  position: relative;
  .button-currentmylocation {
    position: absolute;
    bottom: $spacing-md;
    right: $spacing-md;
  }
}
</style>

<style lang="scss">
.map-parking {
  .gm-style .gm-style-iw-c {
    padding: 12px !important;
    .gm-style-iw-d {
      overflow: auto !important;
    }
  }
}
</style>
