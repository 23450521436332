<template>
  <label
    class="form-input-text"
    :class="[
      size,
      {
        'is-focus': isFocus,
        'is-error': isError,
        'is-disabled': disabled,
      },
    ]"
  >
    <input
      v-model="localValue"
      class="input"
      :type="type"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :disabled="disabled"
      @focus="inputFocus"
      @blur="inputBlur"
    />
  </label>
</template>

<script>
export default {
  name: "FormInputText",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    size: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    inputFocus() {
      this.isFocus = true;
    },
    inputBlur() {
      this.isFocus = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form-input-text {
  display: flex;
  align-items: center;
  width: 100%;
  height: $input-height;
  cursor: text;
  position: relative;

  &.small {
    width: 136px;
  }

  &.medium {
    width: 100%;
  }

  &.large {
    width: 704px;
  }

  &.is-focus {
    > .input {
      border-color: $black100;
    }
  }

  &.is-error {
    > .input {
      color: $red300;
      background: $red100;
      border-color: $red100;
    }
  }

  &.is-disabled {
    > .input {
      background: $gray400;
      border-color: $gray400;
      color: $black200;
      -webkit-text-fill-color: $black200;
      -webkit-opacity: 1;
    }
  }

  > .input {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: 0 $spacing-xxs;
    background: $white100;
    box-sizing: border-box;
    border: 1px solid $gray400;
    outline: none;
    border-radius: $radius;
    color: $black400;
    font: $sans-none-16;

    &::placeholder {
      color: $black100;
    }
  }
}
</style>
