const axios = require("axios");
import router from "../router/index";
import store from "../store/index";
import { LINKAGE_STATUS } from "@/utils/const";

const execute = (method, apiPath, requestData, inputOptions) => {
  return new Promise((resolve) => {
    const defaultOptions = { withAuthorization: true };
    const options = Object.assign(defaultOptions, inputOptions);
    // console.log("requestData", requestData);
    // console.log("options", options);

    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": `${process.env.VUE_APP_BASE_API_KEY}`,
    };
    if (options.withAuthorization) headers.Authorization = getIdToken();

    const config = {
      method: method,
      url: `${process.env.VUE_APP_BASE_API_URL}${apiPath}`,
      data: requestData,
      headers: headers,
    };
    if (method === "GET") config.params = requestData;

    axios(config)
      .then((res) => {
        // console.log("response", res.data);
        const linkageStatus = res.data.item.linkage_status;
        // リンケージが必要な画面に未リンケージユーザーがアクセスした場合、リンケージ画面に遷移する
        if (linkageStatus === LINKAGE_STATUS.REDIRECT_TO_LINKAGE) {
          router.push({ name: "Y1W060" });
          return;
        }
        store.dispatch("setLinkageStatus", { linkageStatus: linkageStatus });
        resolve(res);
      })
      .catch((err) => {
        console.log("error", err);
        if (err.response) {
          const status = err.response.status;

          // トークンエラー
          if (401 === status) {
            console.log(
              `401_ERROR_LOGOUT SESSION_ID=${getIdToken()} URL=${
                err.response.request.responseURL
              }`
            );
            axios({
              method: "post",
              url: `${process.env.VUE_APP_BASE_API_URL}/user/verifyloginlog`,
              data: {
                memo: "LOGOUT FOR 401_ERROR",
                localstorage_session_id: getIdToken(),
                url: err.response.request.responseURL,
              },
              headers: headers,
            })
              .then(() => {
                console.log("VRIFY LOG CALLED");
                localStorage.removeItem(
                  process.env.VUE_APP_LOCALSTORAGE_SESSION_ID
                );
                window.gigya.accounts.logout({
                  callback: () => {
                    // 401:トークンエラー時はgigya.logout実行後スクリーンセット画面に遷移。
                    console.log(
                      `LOGOUT FOR 401_ERROR SESSION_ID=${getIdToken()} URL=${
                        err.response.request.responseURL
                      }`
                    );
                    router.push({ name: "Y1W000" });
                    store.dispatch("stopLoading");
                  },
                });
              })
              .catch((err) => {
                console.log("verifylogin_log_error", err);
              });

            return;
          }

          // Not Found
          if (404 === status) {
            router.push({ name: "Y1W900" });
            store.dispatch("stopLoading");
            return;
          }

          // システムエラー
          if (500 === status) {
            router.push({ name: "Y1W910" });
            store.dispatch("stopLoading");
            return;
          }

          // メンテナンス
          if (503 === status) {
            router.push({ name: "Y1W930" });
            store.dispatch("stopLoading");
            return;
          }

          // タイムアウトエラー
          if (504 === status) {
            router.push({ name: "Y1W920" });
            store.dispatch("stopLoading");
            return;
          }
        }

        // その他のエラー
        router.push({ name: "Y1W910" });
        store.dispatch("stopLoading");
      });
  });
};

export const getIdToken = () => {
  return localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_SESSION_ID);
};

export const getApi = (path, data, options) => {
  return execute("GET", path, data, options);
};

export const postApi = (path, data, options) => {
  return execute("POST", path, data, options);
};

// ダッシュボードAPI
export const homeDashboardApi = (data) => {
  return getApi("/home/dashboard", data);
};

// 通知履歴API
export const notificationListApi = (data) => {
  return getApi("/notification/list", data);
};

// 通知詳細API
export const notificationDetailApi = (data) => {
  return getApi("/notification/detail", data);
};

// 設定一覧API
export const settingsListApi = (data) => {
  return getApi("/settings/list", data);
};

// ユーザー情報API
export const settingsInfoApi = (data) => {
  return getApi("/settings/info", data);
};

// 言語設定API
export const getSettingsLanguageApi = (data) => {
  return getApi("/settings/language", data);
};

// 言語設定編集API
export const postSettingsLanguageApi = (data) => {
  return postApi("/settings/language", data);
};

// 単位・書式設定取得API
export const settingsFormatApi = (data) => {
  return getApi("/settings/format", data);
};

// 単位設定更新API
export const settingsUnitApi = (data) => {
  return postApi("/settings/unit", data);
};

// 書式設定更新API
export const settingsNumericruleApi = (data) => {
  return postApi("/settings/numericrule", data);
};

// お問い合わせAPI
export const settingsInquiryApi = (data) => {
  return getApi("/settings/inquiry", data);
};

// 通知設定取得API
export const getSettingsNotificationApi = (data) => {
  return getApi("/settings/notification", data);
};

// 通知設定更新API
export const postSettingsNotificationApi = (data) => {
  return postApi("/settings/notification", data);
};

// バイク情報取得API
export const getSettingsVehicleApi = (data) => {
  return getApi("/settings/vehicle", data);
};

// バイク情報更新API
export const postSettingsVehicleApi = (data) => {
  return postApi("/settings/vehicle", data);
};

// ログインAPI
export const userLoginApi = (data) => {
  return postApi("/user/login", data, { withAuthorization: false });
};

// ログアウトAPI
export const userLogoutApi = (data) => {
  return postApi("/user/logout", data);
};

// リンケージ情報取得API
export const getVehicleLinkageApi = (data) => {
  return getApi("/vehicle/linkage", data);
};

// リンケージ開始API
export const postVehicleLinkageApi = (data) => {
  return postApi("/vehicle/linkage", data);
};

// リンケージ解除API
export const vehicleLinkageRemovalApi = (data) => {
  return postApi("/vehicle/linkage/removal", data);
};

// リンケージ２段階認証検証API
export const vehicleLinkageVerificationApi = (data) => {
  return getApi("/vehicle/linkage/verification", data);
};

// 電費情報取得API
export const vehicleEfficiencyApi = (data) => {
  return getApi("/vehicle/efficiency", data);
};

// 走行履歴一覧取得API
export const vehicleHistoryApi = (data) => {
  return getApi("/vehicle/history/list", data);
};

// 走行軌跡取得API
export const vehicleHistoryTrackApi = (data) => {
  return getApi("/vehicle/history/track", data);
};

// 駐車位置取得API
export const vehicleParkingApi = (data) => {
  return getApi("/vehicle/parking", data);
};

// verifyLoginログ出力API
export const postVerifyLoginLogApi = (data) => {
  return postApi("/user/verifyloginlog", data);
};
