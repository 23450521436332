<template>
  <div class="icon-wrap">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.73 2.33325C9.36425 2.33325 5.83333 5.86417 5.83333 10.2299C5.83333 16.1524 12.3914 17.3781 13.73 25.6666C14.7059 17.3781 21.6266 16.1524 21.6266 10.2299C21.6266 5.86417 18.0957 2.33325 13.73 2.33325ZM13.73 13.0501C12.1732 13.0501 10.9097 11.7866 10.9097 10.2299C10.9097 8.67312 12.1732 7.40966 13.73 7.40966C15.2867 7.40966 16.5502 8.67312 16.5502 10.2299C16.5502 11.7866 15.2867 13.0501 13.73 13.0501Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconMilage",
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 28px;
  height: 28px;
}
</style>
