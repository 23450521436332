<template>
  <div class="battery-meter">
    <div class="chartwrap">
      <ChartDoughnut :battery="batteryAmount" />
    </div>
    <div class="infowrap">
      <IconBattery v-if="isCharging" :is-blinking="batteryAmount !== 100" />
      <div v-else class="text">BATT</div>
      <div v-if="isCharging" class="integer">
        {{ remainingTime }}
      </div>
      <div v-else class="integer">
        {{ battery }}<span v-if="battery" class="unit">%</span>
      </div>
    </div>
  </div>
</template>

<script>
import ChartDoughnut from "@/components/graph/ChartDoughnut.vue";
import IconBattery from "@/components/icon/IconBattery.vue";

export default {
  name: "BatteryMeter",
  components: {
    ChartDoughnut,
    IconBattery,
  },
  props: {
    isCharging: {
      type: Boolean,
      default: false,
    },
    battery: {
      type: String,
      default: "LOW",
    },
    remainingTime: {
      type: String,
      default: "",
    },
  },
  computed: {
    batteryAmount() {
      return Number(this.battery) ? Number(this.battery) : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.battery-meter {
  width: 311px;
  height: 293px;
  margin: 0 auto;
  background-image: url("../../assets/images/Meter.png");
  background-size: cover;
  position: relative;

  > .chartwrap {
    width: 206px;
    height: 191px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > .infowrap {
    > .icon-wrap {
      position: absolute;
      top: 78px;
      left: 50%;
      transform: translateX(-50%);
    }

    > .text {
      width: 130px;
      text-align: center;
      color: $white100;
      font: $sans-none-18-bold;
      position: absolute;
      top: 98px;
      left: 50%;
      transform: translateX(-50%);
    }

    > .integer {
      width: 168px;
      text-align: center;
      position: absolute;
      top: 132px;
      left: 50%;
      transform: translateX(-50%);
      color: $white100;
      font: $bigsholders-none-80;
    }

    > .integer > .unit {
      font: $bigsholders-none-34;
    }
  }
}
</style>
