<template>
  <div class="button-block" :class="['button-block-' + layout]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ButtonBlock",
  props: {
    layout: {
      type: String,
      default: "right",
    },
  },
};
</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
  flex-flow: wrap;

  .button {
    margin: 0 $spacing-xxs 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &.button-block-left {
    justify-content: flex-start;
  }

  &.button-block-center {
    justify-content: center;
  }

  &.button-block-right {
    justify-content: flex-end;
  }
}
</style>
