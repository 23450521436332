<template>
  <canvas id="chart" height="160px" width="343px"></canvas>
</template>

<script>
import Chart from "chart.js";
import moment from "moment";

export default {
  name: "ChartLine",
  props: {
    chartdata: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartdata() {
      // チャートを更新
      this.chart.data.datasets[0].data = this.chartdata.data;
      this.chart.data.labels = this.getLabels();
      this.chart.options.scales.xAxes[0].time = this.getTimeScales();
      this.chart.update();
    },
  },
  mounted() {
    const ctx = document.getElementById("chart").getContext("2d");
    const height = document.getElementById("chart").height;
    const gradientStroke = ctx.createLinearGradient(0, height, 0, 0);
    gradientStroke.addColorStop(1, "rgba(255, 160, 0, 1)");
    gradientStroke.addColorStop(0.5, "rgba(253, 222, 144, 0.7)");
    gradientStroke.addColorStop(0, "rgba(255, 244, 217, 0)");

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.getLabels(),
        datasets: [
          {
            data: this.chartdata.data,
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#fdde90",
            borderWidth: 4,
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0.3,
          },
          point: {
            radius: 1,
          },
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: this.getTimeScales(),
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                fontFamily: "'Noto Sans JP', sans-serif",
                fontSize: 12,
                fontStyle: "bold",
                fontColor: "#ffffff",
                source: "labels",
              },
              bounds: "data",
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: {
            top: 3, // 最大値の点が見切れるため追加
          },
        },
      },
    });
  },
  methods: {
    getLabels() {
      let labels = [];
      if (this.chartdata.isMonthly) {
        const day = moment(this.chartdata.yearmonth);
        labels.push(day.format("YYYY-MM-DD"));
        const daysInMonth = day.daysInMonth();
        for (let i = 5; i <= daysInMonth; i += 5) {
          labels.push(day.date(i).format("YYYY-MM-DD"));
        }
      } else {
        let month = moment(this.chartdata.yearmonth);
        for (let i = 0; i < 12; i++) {
          labels.push(month.format("YYYY-MM"));
          month.add(1, "M");
        }
      }
      return labels;
    },
    getTimeScales() {
      let time = {};
      if (this.chartdata.isMonthly) {
        time = {
          unit: "day",
          displayFormats: {
            day: "D",
          },
        };
      } else {
        time = {
          unit: "month",
          displayFormats: {
            month: "M",
          },
        };
      }
      return time;
    },
  },
};
</script>
