<template>
  <div class="title">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Title",
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  font: $sans-normal-18-bold;
  color: $white100;

  > .menu-title-label {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
