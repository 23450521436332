<template>
  <div class="linkage-stepper">
    <div class="linkage-stepper__block">
      <div v-for="(stepper, index) of steppers" :key="index" class="step">
        <span class="step-check">
          <IconStepperDisable
            v-if="!stepper.acitve && !stepper.done"
            :size="20"
          />
          <IconStepperDisableDone
            v-if="stepper.done && !stepper.acitve"
            :size="20"
          />
          <IconStepperActive
            v-if="stepper.acitve && !stepper.done"
            :size="20"
          />
        </span>
        <p
          class="step-text"
          :class="[
            {
              'is-disabled': stepper.disabled,
            },
          ]"
        >
          {{ stepper.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconStepperDisable from "@/components/icon/IconStepperDisable";
import IconStepperActive from "@/components/icon/IconStepperActive";
import IconStepperDisableDone from "@/components/icon/IconStepperDisableDone";

export default {
  name: "LinkageStepper",
  components: {
    IconStepperDisable,
    IconStepperActive,
    IconStepperDisableDone,
  },
  props: {
    step: {
      type: Number,
      default: 1,
      validator: function (value) {
        return 1 <= value && value <= 4;
      },
    },
  },
  computed: {
    steppers() {
      let steppers = [];
      if (this.step === 1) {
        steppers = [
          { text: "STEP1", acitve: true },
          { text: "STEP2", disabled: true },
          { text: "STEP3", disabled: true },
          { text: "STEP4", disabled: true },
        ];
      } else if (this.step === 2) {
        steppers = [
          { text: "STEP1", done: true, disabled: true },
          { text: "STEP2", acitve: true },
          { text: "STEP3", disabled: true },
          { text: "STEP4", disabled: true },
        ];
      } else if (this.step === 3) {
        steppers = [
          { text: "STEP1", done: true, disabled: true },
          { text: "STEP2", done: true, disabled: true },
          { text: "STEP3", acitve: true },
          { text: "STEP4", disabled: true },
        ];
      } else if (this.step === 4) {
        steppers = [
          { text: "STEP1", done: true, disabled: true },
          { text: "STEP2", done: true, disabled: true },
          { text: "STEP3", done: true, disabled: true },
          { text: "STEP4", acitve: true },
        ];
      }
      return steppers;
    },
  },
};
</script>

<style lang="scss" scoped>
.linkage-stepper {
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 94%;
      background-color: $black100;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: $stepper-line-z-index;
    }

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: $stepper-step-z-index;

      .step-text {
        font: $sans-none-12-bold;
        color: $primary200;
        margin-top: $spacing-xs;

        &.is-disabled {
          color: $black100;
        }
      }
    }
  }
}
</style>
