<template>
  <div class="message-error">
    <IconWarning :size="40" />
    <p class="text">{{ message }}</p>
  </div>
</template>

<script>
import IconWarning from "@/components/icon/IconWarning.vue";
export default {
  name: "MessageError",
  components: {
    IconWarning,
  },
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.message-error {
  height: 148px;
  width: 295px;
  overflow: scroll;
  background-color: rgba($black900, 0.7);
  font: $sans-tight-12;
  color: $white100;
  border-radius: $radius;
  box-sizing: border-box;
  padding: $spacing-xxs $spacing-sm 0;

  > .icon-base {
    width: 40px;
    margin: 0 auto;
  }

  > .text {
    margin-top: $spacing-xxxs;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
</style>
