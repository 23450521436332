<template>
  <div class="runninginfo-block">
    <div class="itemwrap">
      <slot></slot>
    </div>
    <div class="date">
      {{ date }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RunningInfoBlock",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.runninginfo-block {
  max-width: 375px;
  width: 100%;
  margin: 0 auto;

  .itemwrap {
    margin: 0 $spacing-xxxs;
    display: flex;

    > .runninginfo-item:not(:last-child) {
      margin-right: $spacing-xxxxs;
    }
  }

  > .date {
    height: 12px;
    color: $gray400;
    font: $sans-none-12;
    margin: $spacing-xxxs $spacing-xxxs 0;

    > .time {
      margin-left: $spacing-xxxxs;
    }
  }
}
</style>
