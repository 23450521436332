<template>
  <Layout
    :title="text.page_title"
    :is-full-screen="true"
    :show-back-button="true"
    @click="onReturn"
  >
    <div v-if="item.tracks" class="map-wrap">
      <MapTrack :language="item.language" :tracks="item.tracks" />
    </div>
    <div v-if="!item.tracks" class="error-wrap">
      <p class="not-found">{{ text.gps_error }}</p>
    </div>
    <RunningInfoBlock :date="item.received_datetime">
      <RunningInfoItem>
        <div class="trajectory-info medium">
          <div class="heading">
            <IconMilage />
          </div>
          <div class="integer">
            {{ item.running_distance
            }}<span class="unit">{{ item.running_distance_unit }}</span>
          </div>
        </div>
      </RunningInfoItem>
      <RunningInfoItem>
        <div class="trajectory-info small">
          <div class="heading">
            <IconAverageSpeed class="mr-4" />
            <p class="text">AVG</p>
          </div>
          <div class="integer">
            {{ item.average_speed
            }}<span class="unit">{{ item.average_speed_unit }}</span>
          </div>
        </div>
      </RunningInfoItem>
      <RunningInfoItem>
        <div class="trajectory-info small">
          <div class="heading">
            <IconRunningTime />
          </div>
          <div class="integer">
            {{ item.running_time
            }}<span class="unit">{{ item.running_time_unit }}</span>
          </div>
        </div>
      </RunningInfoItem>
      <RunningInfoItem>
        <div class="trajectory-info large">
          <div class="heading">
            <IconElectricGraph class="mr-4" />
            <p class="text">AVG</p>
          </div>
          <div class="integer">
            {{ item.electricity_costs
            }}<span class="unit">{{ item.electricity_costs_unit }}</span>
          </div>
        </div>
      </RunningInfoItem>
    </RunningInfoBlock>
  </Layout>
</template>

<script>
import { vehicleHistoryTrackApi } from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import MapTrack from "@/components/map/MapTrack";
import RunningInfoBlock from "@/components/runninginfo/RunningInfoBlock";
import RunningInfoItem from "@/components/runninginfo/RunningInfoItem.vue";
import IconMilage from "@/components/icon/IconMilage.vue";
import IconAverageSpeed from "@/components/icon/IconAverageSpeed.vue";
import IconRunningTime from "@/components/icon/IconRunningTime.vue";
import IconElectricGraph from "@/components/icon/IconElectricGraph.vue";
import moment from "moment";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W030",
  components: {
    MapTrack,
    Layout,
    RunningInfoBlock,
    RunningInfoItem,
    IconMilage,
    IconAverageSpeed,
    IconRunningTime,
    IconElectricGraph,
  },
  mixins: [ApiMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!from.name) vm.isLandingPage = true;
    });
  },
  data() {
    return {
      isLandingPage: false,
      item: {
        running_distance: "---",
        running_distance_unit: "km",
        average_speed: "---",
        average_speed_unit: "km/h",
        running_time: "---",
        running_time_unit: "",
        electricity_costs: "---",
        electricity_costs_unit: "Wh/km",
      },
      text: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        const date = new Date();
        vehicleHistoryTrackApi({
          id: this.$route.params.id,
          timezone_diff_min: date.getTimezoneOffset(),
        }).then((res) => {
          this.item = res.data.item;
          this.text = res.data.text;
          this.stopLoading();
        });
      });
    },
    onReturn() {
      sendEvent(
        SCREEN_ID.NAVIGATION,
        ACTION.NAVIGATION.CLICK_BUTTON_BACKTO_TRAVEL_TRAJECTORY
      );
      if (this.isLandingPage) {
        this.$router.push({
          name: "Y1W035",
          query: {
            year: moment(this.item.received_datetime, "ddd, DD MMM YYYY HH:mm")
              .year()
              .toString(),
            page: 1,
          },
        });
        return;
      }
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  > .main > .inner > .map-wrap,
  .error-wrap {
    height: calc(100% - 130px);
  }

  > .main > .inner > .runninginfo-block {
    margin-top: $spacing-sm;
    margin-bottom: $spacing-xs;
  }

  > .main > .inner > .error-wrap > .not-found {
    color: $white100;
    font: $sans-tight-16;
    padding: $spacing-md;
    word-wrap: break-word;
  }

  ::v-deep {
    .main {
      min-height: 500px;
    }
  }
}
</style>
