import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMoment from "vue-moment";
import VueScrollTo from "vue-scrollto";

Vue.use(VueMoment);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
