<template>
  <div class="icon-wrap">
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m25.3333 8.54675-1.88-1.88-7.4533 7.45335-7.45334-7.45335-1.88 1.88 7.45334 7.45335-7.45334 7.4533 1.88 1.88 7.45334-7.4533 7.4533 7.4533 1.88-1.88-7.4533-7.4533z"
        fill="#fff"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconMenuClose",
};
</script>
<style lang="scss" scoped>
.icon-wrap {
  width: 32px;
  height: 32px;
}
</style>
