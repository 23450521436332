<template>
  <Layout title="404" :is-hide-bottom-navigation="true" :title-only="true">
    <Title class="error-message">Not Found</Title>
    <ButtonBlock layout="center" class="button-block">
      <Button @click="toHome">HOME</Button>
    </ButtonBlock>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/Layout";
import Title from "@/components/layout/Title";
import ButtonBlock from "@/components/button/ButtonBlock";
import Button from "@/components/button/Button";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W900",
  components: {
    Layout,
    Title,
    ButtonBlock,
    Button,
  },
  methods: {
    toHome() {
      this.$router.push({ name: "Y1W020" });
      sendEvent(SCREEN_ID.Y1W900, ACTION.Y1W900.CLICK_LINK_HOME);
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  display: flex;
  align-items: center;
  padding: 0 $spacing-md;
  height: 80px;
}

.button-block {
  margin: $spacing-md 0;
}
</style>
