<template>
  <div class="form-block">
    <FormLabel v-if="label">{{ label }}</FormLabel>
    <div class="form-item"><slot /></div>
  </div>
</template>

<script>
import FormLabel from "@/components/form/FormLabel";

export default {
  name: "FormBlock",
  components: { FormLabel },
  props: {
    label: {
      type: [String, Array],
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.form-block {
  .form-item {
    margin-top: $spacing-xs;
  }
}
</style>
