<template>
  <div class="runninginfo-item">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "RunningInfoItem",
};
</script>
<style lang="scss" scoped>
.runninginfo-item {
  width: 100%;

  > .home-info,
  .trajectory-info {
    width: 100%;
    background-color: $black300;
    color: $white100;
    border-radius: $radius;
    display: inline-block;
    box-sizing: border-box;

    > .heading {
      display: flex;
      height: 28px;
      align-items: center;
    }

    > .heading > .text {
      font: $sans-none-10;
    }

    > .range,
    .integer,
    .integer-min,
    .integer-large {
      height: 34px;
      width: 100%;
      font: $bigsholders-none-34;
      text-align: right;

      > .unit {
        font: $bigsholders-none-14;
      }
    }
  }

  > .home-info {
    padding: $spacing-xxxxs;

    > .range > .unit {
      margin-left: $spacing-xxxxs;
    }
  }

  > .trajectory-info {
    padding: $spacing-xxxxs 2px;

    > .heading > .mr-4 {
      margin-right: $spacing-xxxxs;
    }

    > .integer {
      @media screen and (max-width: 374px) {
        font-size: 28px;
      }
    }
  }

  > .small {
    width: 72px;
  }

  > .medium {
    width: 88px;
  }

  > .large {
    width: 115px;
  }

  > .small,
  .medium,
  .large {
    @media screen and (max-width: 374px) {
      width: 100%;
    }
  }
}
</style>
