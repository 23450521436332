<template>
  <IconBase :size="size">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 11.8036L30.9527 30.7273H9.04727L20 11.8036ZM20 6L4 33.6364H36L20 6ZM21.4545 26.3636H18.5455V29.2727H21.4545V26.3636ZM21.4545 17.6364H18.5455V23.4545H21.4545V17.6364Z"
        fill="#F44336"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from "./IconBase";
export default {
  name: "IconWarning",
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-base {
  .icon {
    svg {
      vertical-align: bottom;
    }
  }
}
</style>
