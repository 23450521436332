<template>
  <ul class="list">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: "List",
};
</script>

<style lang="scss" scoped>
.list {
  > .list-item + .list-item {
    border-top: none;
  }
  > .head {
    height: 54px;
    color: $white100;
    font: $sans-none-14-bold;
    display: flex;
    justify-content: space-between;
    padding-top: $spacing-sm;
    padding-right: $spacing-sm;
    padding-bottom: $spacing-xs;
    padding-left: $spacing-sm;
  }
  > .head > .date {
    width: 140px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  > .head > .runwrap {
    width: calc(100% - 140px);
    display: flex;
  }

  > .head > .runwrap > .min,
  .range {
    width: 50%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
