<template>
  <Layout class="page" :title="text.page_title" :is-full-screen="true">
    <MapParking
      v-if="item.gps_location_error === 0 && item.parking_location_error === 0"
      :language="item.language"
      :position="item.position"
      :datetime="item.datetime"
      :error-message="text.current_location_error_dialog.title"
    />
    <p v-else-if="item.gps_location_error" class="error">
      {{ text.gps_location_error.message }}<br />
      {{ text.gps_location_error.datetime_label }}：{{ item.datetime }}
    </p>
    <p v-else-if="item.parking_location_error" class="error">
      {{ text.parking_location_error }}
    </p>
  </Layout>
</template>

<script>
import { vehicleParkingApi } from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import MapParking from "@/components/map/MapParking";
import ApiMixin from "@/mixins/ApiMixin";

export default {
  name: "Y1W040",
  components: {
    Layout,
    MapParking,
  },
  mixins: [ApiMixin],
  data() {
    return {
      item: {},
      text: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        const date = new Date();
        vehicleParkingApi({ timezone_diff_min: date.getTimezoneOffset() }).then(
          (res) => {
            this.item = res.data.item;
            this.text = res.data.text;
            this.stopLoading();
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  .error {
    color: $white100;
    font: $sans-tight-16;
    margin: $spacing-md;
    word-wrap: break-word;
  }
}
</style>
