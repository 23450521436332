import { render, staticRenderFns } from "./RunningInfoBlock.vue?vue&type=template&id=5d97ce76&scoped=true&"
import script from "./RunningInfoBlock.vue?vue&type=script&lang=js&"
export * from "./RunningInfoBlock.vue?vue&type=script&lang=js&"
import style0 from "./RunningInfoBlock.vue?vue&type=style&index=0&id=5d97ce76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d97ce76",
  null
  
)

export default component.exports