<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M12.1493 15.9971C11.9284 15.9971 11.716 15.9127 11.546 15.7525L3.73711 8.00234L11.546 0.243743C11.8774 -0.085154 12.4127 -0.085154 12.7441 0.243743C13.0755 0.57264 13.0755 1.10394 12.7441 1.43283L6.13332 7.99391L12.7441 14.555C13.0755 14.8839 13.0755 15.4152 12.7441 15.7441C12.5827 15.9127 12.3703 15.9971 12.1493 15.9971Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(16) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconArrowLeft",
};
</script>
