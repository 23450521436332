<template>
  <Layout :title="text.page_title">
    <div class="wrap">
      <YearMonth
        v-model="currentDate"
        class="yearmonth-wrap"
        :is-monthly="false"
        @onPrev="changeYearPrev"
        @onNext="changeYearNext"
      />
    </div>
    <ListNotification
      :items="notifications"
      :empty-message="text.sentence"
      @onClick="toDetailPage"
    />
    <Pagination
      v-if="Object.keys(pageInfo).length"
      v-model="currentPage"
      :per-page="pageInfo.limit"
      :total-count="pageInfo.count"
      @click="changePage"
    />
  </Layout>
</template>

<script>
import { notificationListApi } from "@/utils/ApiHelper";
import { mapActions, mapGetters } from "vuex";
import ApiMixin from "@/mixins/ApiMixin";
import moment from "moment";
import Layout from "@/components/layout/Layout";
import YearMonth from "@/components/yearmonth/YearMonth";
import ListNotification from "@/components/list/ListNotification";
import Pagination from "@/components/pagination/Pagination";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W100",
  components: {
    Layout,
    YearMonth,
    ListNotification,
    Pagination,
  },
  mixins: [ApiMixin],
  data() {
    return {
      currentDate: moment(),
      currentPage: 1,
      notifications: [],
      pageInfo: {},
      text: {},
    };
  },
  computed: {
    ...mapGetters(["getPosition"]),
    currentYear() {
      return this.currentDate.format("YYYY");
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
    notifications() {
      this.$nextTick(() => {
        if (this.getPosition) {
          scrollTo(this.getPosition.x, this.getPosition.y);
          this.clearPosition();
        }
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "clearPosition"]),
    fetchData() {
      this.startLoading();
      if (this.$route.query.year) {
        this.currentDate = moment(this.$route.query.year, "YYYY");
      } else {
        this.currentDate = moment();
      }
      this.currentPage = Number(this.$route.query.page) || 1;
      this.$_verifyGigyaLogin(() => {
        const date = new Date();
        notificationListApi({
          year: this.currentYear,
          page: this.currentPage,
          timezone_diff_min: date.getTimezoneOffset(),
        }).then((res) => {
          this.notifications = res.data.item.notifications;
          this.pageInfo = res.data.item.page_info;
          this.text = res.data.text;
          this.stopLoading();
        });
      });
    },
    changeYearPrev() {
      this.$router.push({
        name: "Y1W100",
        query: { year: this.currentYear, page: 1 },
      });
      sendEvent(SCREEN_ID.Y1W100, ACTION.Y1W100.CLICK_BUTTON_PREVIOUS_YEAR);
    },
    changeYearNext() {
      this.$router.push({
        name: "Y1W100",
        query: { year: this.currentYear, page: 1 },
      });
      sendEvent(SCREEN_ID.Y1W100, ACTION.Y1W100.CLICK_BUTTON_NEXT_YEAR);
    },
    changePage() {
      this.$router.push({
        name: "Y1W100",
        query: { year: this.currentYear, page: this.currentPage },
      });
      sendEvent(SCREEN_ID.Y1W100, ACTION.Y1W100.CLICK_BUTTON_PAGE);
    },
    toDetailPage(notificationId) {
      this.$router.push({
        name: "Y1W105",
        params: { id: notificationId },
      });
      sendEvent(SCREEN_ID.Y1W100, ACTION.Y1W100.CLICK_LINK_MESSAGE_DETAIL);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  > .main > .inner > .wrap {
    width: 100%;
    max-width: 768px;
    box-sizing: border-box;
    background-color: $black500;
    padding-top: $spacing-md;
    padding-bottom: $spacing-sm;
    position: fixed;
  }

  .yearmonth-wrap {
    width: 112px;
    margin: 0 auto;
  }

  .list-notification {
    padding-top: 80px;
  }

  .pagination-wrap {
    margin: $spacing-sm auto $spacing-md;
  }
}
</style>
