<template>
  <li
    class="menu-item"
    :class="[
      {
        'is-disabled': disabled,
      },
    ]"
    :disabled="disabled"
    @click="click"
  >
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "DrawerMenuItem",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      if (!this.disabled) this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  height: 56px;
  border-top: 1px solid $primary500;
  border-bottom: 1px solid $primary500;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: $spacing-md;
  padding-right: $spacing-md;
  background-color: $primary700;
  cursor: pointer;

  > .menu-item-label {
    color: $white100;
    font: $sans-normal-14;
    text-decoration: none;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.is-disabled {
    background-color: rgba($primary500, 0.2);
    cursor: not-allowed;
    > .menu-item-label {
      color: $primary300;
    }
  }
}
</style>
