<template>
  <div>
    <div id="gigya"></div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import { settingsInfoApi } from "@/utils/ApiHelper";
import ApiMixin from "@/mixins/ApiMixin";
import Loading from "@/components/layout/Loading.vue";

export default {
  name: "Y1W005",
  components: {
    Loading,
  },
  mixins: [ApiMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$_verifyGigyaLogin(() => {
        settingsInfoApi().then((res) => {
          const language = res.data.item.language;
          window.gigya.accounts.showScreenSet({
            screenSet: "Standard-3.0-ProfileUpdate",
            containerID: "gigya",
            lang: language,
            deviceType: "auto",
            onHide: () => {
              this.$router.push({ name: "Y1W020" });
            },
          });
          this.isLoading = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
