<template>
  <button class="chip" :class="{ active }" @click="$emit('onClick')">
    <span class="label">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "Chip",
  props: {
    label: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  box-sizing: border-box;
  border: 2px solid $primary300;
  border-radius: 30px;
  background: transparent;
  font: $sans-normal-14-bold;
  color: $white100;
  cursor: pointer;

  &.active {
    background-color: $primary300;
  }

  > .label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
