import Vue from "vue";
import VueRouter from "vue-router";
import Y1W000 from "../views/Y1W000.vue";
import Y1W005 from "../views/Y1W005.vue";
import Y1W020 from "../views/Y1W020.vue";
import Y1W030 from "../views/Y1W030.vue";
import Y1W035 from "../views/Y1W035.vue";
import Y1W040 from "../views/Y1W040.vue";
import Y1W050 from "../views/Y1W050.vue";
import Y1W060 from "../views/Y1W060.vue";
import Y1W070 from "../views/Y1W070.vue";
import Y1W080 from "../views/Y1W080.vue";
import Y1W090 from "../views/Y1W090.vue";
import Y1W100 from "../views/Y1W100.vue";
import Y1W105 from "../views/Y1W105.vue";
import Y1W110 from "../views/Y1W110.vue";
import Y1W120 from "../views/Y1W120.vue";
import Y1W900 from "../views/Y1W900.vue";
import Y1W910 from "../views/Y1W910.vue";
import Y1W920 from "../views/Y1W920.vue";
import Y1W930 from "../views/Y1W930.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Y1W000",
    component: Y1W000,
  },
  {
    path: "/userinfo",
    name: "Y1W005",
    component: Y1W005,
  },
  {
    path: "/home",
    name: "Y1W020",
    component: Y1W020,
  },
  {
    path: "/trace/:id",
    name: "Y1W030",
    component: Y1W030,
  },
  {
    path: "/trace",
    name: "Y1W035",
    component: Y1W035,
  },
  {
    path: "/parkingspot",
    name: "Y1W040",
    component: Y1W040,
  },
  {
    path: "/electricmileage",
    name: "Y1W050",
    component: Y1W050,
  },
  {
    path: "/linkage",
    name: "Y1W060",
    component: Y1W060,
  },
  {
    path: "/language",
    name: "Y1W070",
    component: Y1W070,
  },
  {
    path: "/motorbike",
    name: "Y1W080",
    component: Y1W080,
  },
  {
    path: "/noticesetting",
    name: "Y1W090",
    component: Y1W090,
  },
  {
    path: "/notice",
    name: "Y1W100",
    component: Y1W100,
  },
  {
    path: "/notice/:id",
    name: "Y1W105",
    component: Y1W105,
  },
  {
    path: "/contact",
    name: "Y1W110",
    component: Y1W110,
  },
  {
    path: "/unitsetting",
    name: "Y1W120",
    component: Y1W120,
  },
  {
    path: "/500",
    name: "Y1W910",
    component: Y1W910,
  },
  {
    path: "/504",
    name: "Y1W920",
    component: Y1W920,
  },
  {
    path: "/503",
    name: "Y1W930",
    component: Y1W930,
  },
  {
    path: "*",
    name: "Y1W900",
    component: Y1W900,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      store.dispatch("savePosition", savedPosition);
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    store.dispatch("setInitial", { initial: true });
  }
});

export default router;
