import Treasure from "td-js-sdk";
import UAParser from "ua-parser-js";
// import { postVerifyLoginLogApi } from "@/utils/ApiHelper";
// import { getIdToken } from "@/utils/ApiHelper";

const td = new Treasure({
  host: process.env.VUE_APP_TREASUREDATA_HOST,
  database: process.env.VUE_APP_TREASUREDATA_DATABASE,
  writeKey: process.env.VUE_APP_TREASUREDATA_WRITEKEY,
});
const parser = UAParser();

const getGigyaAccount = () => {
  return new Promise((resolve) => {
    // ログイン中であればトレジャーデータに操作ログを送信。
    window.gigya.accounts.verifyLogin({
      callback: (res) => {
        if (res.errorCode === 0) {
          resolve(res);
          // } else {
          // console.log(
          //   `NOT LOGOUT FOR TRESURE_VERIFY_ERROR SESSION_ID=${getIdToken()} response=${res}`
          // );
          // postVerifyLoginLogApi({
          //   memo: "NOT LOGOUT FOR TRESURE_VERIFY_ERROR",
          //   localstorage_session_id: getIdToken(),
          //   res: res,
          // });
        }
      },
    });
  });
};

export const sendEvent = async (screenId, action) => {
  const gigyaAccount = await getGigyaAccount();
  return new Promise((resolve) => {
    td.addRecord(process.env.VUE_APP_TREASUREDATA_TABLE, {
      os_type: parser.os.name,
      os_ver: parser.os.version,
      app_ver: "",
      gigya_uid: gigyaAccount.UID,
      device_type: parser.device.type,
      device_lang: window.navigator.language,
      device_model: parser.device.model,
      gigya_country: gigyaAccount.profile.country,
      screen_id: screenId,
      action: action,
    });
    resolve();
  });
};

export const SCREEN_ID = Object.freeze({
  NAVIGATION: "headerViewController",
  DRAWERMENU: "settingViewController",
  Y1W035: "TravelhistoryViewController",
  Y1W050: "ElectricmileageViewController",
  Y1W060_STEP1: "LinkageStep1ViewController",
  Y1W060_STEP2: "LinkageStep2ViewController",
  Y1W060_STEP3: "LinkageStep3ViewController",
  Y1W060_STEP4: "LinkageStep4ViewController",
  Y1W060_LINKAGED: "LinkageConnectedViewController",
  Y1W070: "languageSettingViewController",
  Y1W080: "VehicleInfoViewController",
  Y1W090: "NotificationSettingViewController",
  Y1W100: "NotificationHistorySettingViewController",
  Y1W110: "ContactSettingViewController",
  Y1W120: "UnitFormatSettingViewController",
  Y1W900: "NoFoundSettingViewController",
  Y1W910: "500ErrorSettingViewController",
  Y1W920: "TimeoutErrorSettingViewController",
  Y1W930: "MaintenanceSettingViewController",
});

export const ACTION = Object.freeze({
  NAVIGATION: {
    CLICK_BUTTON_MENU: "clickButton_Menu",
    CLICK_BUTTON_HOME: "clickButton_Home",
    CLICK_BUTTON_TRAVEL_HISTORY: "clickButton_TravelHistory",
    CLICK_BUTTON_PARKING_POSITION: "clickButton_ParkingPosition",
    CLICK_BUTTON_ELECTRIC_MILEAGE: "clickButton_ElectricMileage",
    CLICK_BUTTON_BACKTO_TRAVEL_TRAJECTORY: "clickButton_backtoTraveltrajectory",
    CLICK_BUTTON_BACKTO_NOTICE_DETAIL: "clickButton_backtoNoticedetail",
  },
  DRAWERMENU: {
    CLICK_BUTTON_CLOSE: "clickBotton_close",
    LINK_USER_INFO: "link_Userinfo",
    LINK_NOTIFICATION_HISTORY: "link_NotificationHistory",
    LINK_NOTICE_SETTING: "link_NoticeSetting",
    LINK_DISTANCE_UNIT_AND_FORMAT: "link_DistanceunitandFormat",
    LINK_LANGUAGE: "link_Language",
    LINK_FAQ: "link_FAQ",
    LINK_CONTACT: "link_Contact",
    LINK_MOTORCYCLE_INFO: "link_Motorcycleinfo",
    LINK_LINKAGE: "link_Linkage",
    CLICK_BUTTON_LOGOUT: "clickBotton_Logout",
  },
  Y1W035: {
    CLICK_BUTTON_PREVIOUS_YEAR: "clickButton_Previousyear",
    CLICK_BUTTON_NEXT_YEAR: "clickButton_Nextyear",
    LINK_TRAVEL_HISTORY_DETAIL: "link_TravelHistoryDetail",
    CLICK_BUTTON_PAGE: "clickButton_Page",
  },
  Y1W050: {
    CLICK_BUTTON_MONTH: "clickButton_Month",
    CLICK_BUTTON_YEAR: "clickButton_Year",
    CLICK_BUTTON_PREVIOUS_MONTH: "clickButton_PreviousMonth",
    CLICK_BUTTON_NEXT_MONTH: "clickButton_NextMonth",
    CLICK_BUTTON_PREVIOUS_YEAR: "clickButton_PreviousYear",
    CLICK_BUTTON_NEXT_YEAR: "clickButton_NextYear",
  },
  Y1W060_STEP1: {
    CLICK_BUTTON_LINKAGE_STEP2: "clickButton_Linkagestep2",
  },
  Y1W060_STEP2: {
    CLICK_BUTTON_LINKAGE_STEP3: "clickButton_Linkagestep3",
  },
  Y1W060_STEP3: {
    CLICK_BUTTON_LINKAGE_STEP4: "clickButton_Linkagestep4",
  },
  Y1W060_STEP4: {
    CLICK_BUTTON_LINKAGE_COMPLETED: "clickButton_LinkageCompleted",
  },
  Y1W060_LINKAGED: {
    CLICK_BUTTON_LINKAGE_RELEASE: "clickButton_LinkageRelease",
    CLICK_BUTTON_OK: "clickBotton_OK",
    CLICK_BUTTON_CANCEL: "clickBotton_Cancel",
  },
  Y1W070: {
    SELECT_LANGUAGE_JA: "select_language_ja",
    SELECT_LANGUAGE_EN: "select_language_en",
    SELECT_LANGUAGE_ID: "select_language_id",
    SELECT_LANGUAGE_MS: "select_language_ms",
    SELECT_LANGUAGE_ZH: "select_language_zh",
    SELECT_LANGUAGE_TH: "select_language_th",
    CLICK_BUTTON_SETTING: "clickBotton_setting",
  },
  Y1W080: {
    CLICK_BUTTON_REGISTER: "clickBotton_Register",
  },
  Y1W090: {
    CLICK_BUTTON_FULL_CHARGE: "clickBotton_Fullcharge",
    CLICK_BUTTON_CHARGE_ERROR: "clickBotton_ChargeError",
  },
  Y1W100: {
    CLICK_BUTTON_PREVIOUS_YEAR: "clickButton_PreviousYear",
    CLICK_BUTTON_NEXT_YEAR: "clickButton_NextYear",
    CLICK_LINK_MESSAGE_DETAIL: "clickLink_Messagedetail",
    CLICK_BUTTON_PAGE: "clickButton_Page",
  },
  Y1W110: {
    CLICK_LINK_MAILADD: "clickLink_Mailadd",
    CLICK_LINK_FAQ: "clickLink_FAQ",
  },
  Y1W120: {
    SELECT_KM: "select_km",
    SELECT_MILE: "select_mile",
    SELECT_PERIOD: "select_period",
    SELECT_COMMA: "select_comma",
  },
  Y1W900: {
    CLICK_LINK_HOME: "clickLink_Home",
  },
  Y1W910: {
    CLICK_LINK_HOME: "clickLink_Home",
  },
  Y1W920: {
    CLICK_LINK_HOME: "clickLink_Home",
  },
  Y1W930: {
    CLICK_LINK_HOME: "clickLink_Home",
  },
});
