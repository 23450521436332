<template>
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="{ focus: isFocus }"
      d="M11.2504 25.2494V18.3749H16.7496V25.2494H23.6241V14.251H27.75L13.999 0.5L0.25 14.251H4.37389V25.2494H11.2504Z"
      fill="#9B9B9B"
    />
  </svg>
</template>

<script>
export default {
  name: "IconHome",
  props: {
    isFocus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.focus {
  fill: $yellow300;
}
</style>
