<template>
  <MapBase :language="language" :center="tracks[0]" @init="init" />
</template>

<script>
import MapBase from "@/components/map/MapBase";

export default {
  name: "MapTrack",
  components: {
    MapBase,
  },
  props: {
    language: {
      type: String,
      required: true,
      default: "",
    },
    tracks: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    init(map) {
      const bounds = new window.google.maps.LatLngBounds();

      // マーカー作成
      this.tracks.forEach((track, index) => {
        if (index === 0) {
          const marker = new window.google.maps.Marker({
            position: track,
            icon: {
              url: require("@/assets/images/start.svg"),
            },
            map: map,
          });
          bounds.extend(marker.position);
          return;
        }

        if (index === this.tracks.length - 1) {
          const marker = new window.google.maps.Marker({
            position: track,
            icon: {
              url: require("@/assets/images/goal.svg"),
            },
            map: map,
          });
          bounds.extend(marker.position);
          return;
        }

        // const marker = new window.google.maps.Marker({
        //   position: track,
        //   icon: {
        //     url: require("@/assets/images/point.svg"),
        //     size: new window.google.maps.Size(28, 28),
        //     anchor: new window.google.maps.Point(14, 10),
        //   },
        //   map: map,
        // });
        // bounds.extend(marker.position);

        bounds.extend(track);
      });

      // 地図表示領域をマーカー位置に合わせて拡大
      map.fitBounds(bounds);

      // ポリライン
      const polyline = new window.google.maps.Polyline({
        path: this.tracks,
        geodesic: true,
        strokeColor: "#1006F5",
        // strokeOpacity: 1.0,
        // strokeWeight: 2,
      });
      polyline.setMap(map);
    },
  },
};
</script>
