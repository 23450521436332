<template>
  <Layout :title="text.page_title">
    <p class="contact-text">
      {{ sentences[0] }}<br /><br />
      <a :href="`mailto:${text.contact_email}`" @click="clickEmail">{{
        text.contact_email
      }}</a>
    </p>
    <p class="contact-text">
      {{ sentences[1] }}
    </p>
    <ButtonBlock v-if="text.faq_button" layout="center" class="button-block">
      <Button type="normal" size="small" @click="linkToNewTab">
        {{ text.faq_button }}
      </Button>
    </ButtonBlock>
  </Layout>
</template>

<script>
import { settingsInquiryApi } from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import ButtonBlock from "@/components/button/ButtonBlock";
import Button from "@/components/button/Button";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W110",
  components: {
    Layout,
    ButtonBlock,
    Button,
  },
  mixins: [ApiMixin],
  data() {
    return {
      text: {},
      sentences: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        settingsInquiryApi().then((res) => {
          this.text = res.data.text;
          this.sentences = res.data.text.sentences;
          this.stopLoading();
        });
      });
    },
    linkToNewTab() {
      window.open(this.text.faq_url, "_blank");
      sendEvent(SCREEN_ID.Y1W110, ACTION.Y1W110.CLICK_LINK_FAQ);
    },
    clickEmail() {
      sendEvent(SCREEN_ID.Y1W110, ACTION.Y1W110.CLICK_LINK_MAILADD);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-text {
  font: $sans-tight-14;
  padding: $spacing-md;
  color: $white100;
  word-wrap: break-word;

  &:first-child {
    padding-bottom: 0;
  }

  a {
    color: $primary200;
    text-decoration: none;
  }
}

.button-block {
  margin-bottom: $spacing-md;
}
</style>
