<template>
  <canvas id="chart" width="100%" height="100%"></canvas>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "ChartDoughnut",
  props: {
    battery: {
      type: Number,
      default: 0,
      validator: function (value) {
        return 0 <= value && value <= 100;
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    color() {
      return this.battery <= 20 ? "#ffa000" : "#3EBF13";
    },
  },
  watch: {
    battery() {
      // バッテリー残量の設定
      this.chart.data.datasets[0].data = [this.battery, 100 - this.battery];
      // 色の設定
      this.chart.data.datasets[0].backgroundColor = [this.color, "#5c5c5c"];
      // チャートを更新
      this.chart.update();
    },
  },
  mounted() {
    const ctx = document.getElementById("chart").getContext("2d");
    this.chart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["remaining", "blank"],
        datasets: [
          {
            data: [this.battery, 100 - this.battery],
            backgroundColor: [this.color, "#5c5c5c"],
            borderWidth: 0,
          },
        ],
      },
      options: {
        cutoutPercentage: 97,
        rotation: (2 / 3) * Math.PI,
        circumference: (300 / 180) * Math.PI,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  },
};
</script>
