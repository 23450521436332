<template>
  <Layout :title="pageTitle">
    <List class="notice-list">
      <ListItem>
        <div class="setting">
          <div class="left">{{ chargingCompletedLabel }}</div>
          <div class="right">
            <FormSwitch
              v-model="chargingCompleted"
              @change="changeChargingCompleted"
            />
          </div>
        </div>
      </ListItem>
      <ListItem>
        <div class="setting">
          <div class="left">{{ chargingAbnormalityLabel }}</div>
          <div class="right">
            <FormSwitch
              v-model="chargingAbnormality"
              @change="changeChargingAbnormality"
            />
          </div>
        </div>
      </ListItem>
    </List>
  </Layout>
</template>

<script>
import {
  getSettingsNotificationApi,
  postSettingsNotificationApi,
} from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import List from "@/components/list/List.vue";
import ListItem from "@/components/list/ListItem.vue";
import FormSwitch from "@/components/form/FormSwitch.vue";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W090",
  components: {
    Layout,
    List,
    ListItem,
    FormSwitch,
  },
  mixins: [ApiMixin],
  data() {
    return {
      chargingCompleted: false,
      chargingAbnormality: false,
      pageTitle: "",
      chargingCompletedLabel: "",
      chargingAbnormalityLabel: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        getSettingsNotificationApi().then((res) => {
          this.chargingCompleted = res.data.item.charging_completed;
          this.chargingAbnormality = res.data.item.charging_abnormality;
          this.pageTitle = res.data.text.page_title;
          this.chargingCompletedLabel = res.data.text.charging_completed.label;
          this.chargingAbnormalityLabel =
            res.data.text.charging_abnormality.label;
          this.stopLoading();
        });
      });
    },
    updateSettings(params) {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        postSettingsNotificationApi(params).then(() => this.stopLoading());
      });
    },
    changeChargingCompleted() {
      this.updateSettings({
        charging_completed: this.chargingCompleted,
      });
      sendEvent(SCREEN_ID.Y1W090, ACTION.Y1W090.CLICK_BUTTON_FULL_CHARGE);
    },
    changeChargingAbnormality() {
      this.updateSettings({
        charging_abnormality: this.chargingAbnormality,
      });
      sendEvent(SCREEN_ID.Y1W090, ACTION.Y1W090.CLICK_BUTTON_CHARGE_ERROR);
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-list {
  .list-item {
    &:first-child {
      border-top: none;
    }
  }
}
</style>
