<template>
  <div class="icon-wrap">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7682 6.00146H10.8586L7.29842 22.6658H25.3299L21.7682 6.00146ZM11.9385 7.34819H15.6392V9.33906H16.9765V7.34819H20.6914L23.6769 21.3175H8.95301L11.9385 7.34819Z"
        fill="white"
      />
      <path
        d="M16.9813 12.0295H15.6441V14.7261H16.9813V12.0295Z"
        fill="white"
      />
      <path
        d="M16.9813 17.4163H15.6441V20.1129H16.9813V17.4163Z"
        fill="white"
      />
      <path d="M4.87343 19.8289H2V20.5519H4.87343V19.8289Z" fill="white" />
      <path d="M4.87343 21.1077H2V21.7187H4.87343V21.1077Z" fill="white" />
      <path d="M4.87343 18.4409H2V19.2745H4.87343V18.4409Z" fill="white" />
      <path d="M4.87343 22.2759H2V22.6643H4.87343V22.2759Z" fill="white" />
      <path
        d="M2.00188 10.5612V17.8963H4.87531V11.2653H7.28457L2.00188 6V10.5612Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconDrivingDistance",
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 28px;
  height: 28px;
}
</style>
