<template>
  <li class="list-item">
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "ListItem",
};
</script>

<style lang="scss" scoped>
.list-item {
  border-top: 1px solid $black200;
  border-bottom: 1px solid $black200;
  box-sizing: border-box;
  height: 56px;
  background-color: transparent;

  &.null {
    border: none;
  }

  ::v-deep {
    > a {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      background-color: transparent;
      color: $white100;
      font: $sans-none-14;
      padding-left: $spacing-md;
      padding-right: $spacing-md;

      &.text {
        font: $sans-tight-16;
      }
    }

    > a > .left {
      min-width: 140px;
    }

    > a > .right,
    .left {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    > .notice,
    .run {
      height: 56px;
      padding-left: $spacing-sm;
      padding-right: $spacing-sm;
      cursor: pointer;
    }

    > .notice > .left {
      margin-right: $spacing-xs;
    }

    > .run {
      justify-content: space-between;
    }

    > .run > .right {
      width: calc(100% - 140px);
      display: flex;
    }

    > .run > .right > .min,
    .range {
      width: 50%;
      text-align: right;
    }
  }

  > .setting {
    height: 100%;
    color: $white100;
    font: $sans-normal-14;
    padding-left: $spacing-sm;
    padding-right: $spacing-sm;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > .setting > .left {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
