var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-input-text",class:[
    _vm.size,
    {
      'is-focus': _vm.isFocus,
      'is-error': _vm.isError,
      'is-disabled': _vm.disabled,
    },
  ]},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"input",attrs:{"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur,"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"input",attrs:{"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur,"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"input",attrs:{"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.localValue)},on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur,"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }