import { userLogoutApi } from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import { postVerifyLoginLogApi } from "@/utils/ApiHelper";
import { getIdToken } from "@/utils/ApiHelper";

export default {
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    $_verifyGigyaLogin(callback) {
      window.gigya.accounts.verifyLogin({
        callback: (res) => {
          console.log("verifyLogin", res.sessionInfo);
          var cookies = document.cookie; //全てのcookieを取り出して
          var cookiesArray = cookies.split(";"); // ;で分割し配列に
          var gmid_value = "None";
          for (var c of cookiesArray) {
            //一つ一つ取り出して
            var cArray = c.split("="); //さらに=で分割して配列に
            if (cArray[0] == "gmid") {
              // 取り出したいkeyと合致したら
              gmid_value = cArray;
            }
          }
          if (res.errorCode === 0) {
            console.log(`OK FOR VERIFY_LOGIN COOKIES=${cookies}`);
            callback();
          } else {
            // console.log(`verifyLogin response error code : ${res.errorCode}`);
            postVerifyLoginLogApi({
              memo: "LOGOUT FOR VERIFY_ERROR",
              localstorage_session_id: getIdToken(),
              res: res.errorCode,
              gmid: gmid_value,
              sessionInfo: res.sessionInfo,
            });
            console.log(`LOGOUT FOR VERIFY_ERROR COOKIES=${gmid_value}`);
            this.$_logout();
          }
        },
      });
    },
    $_logout() {
      this.startLoading();
      window.gigya.accounts.logout({
        callback: () => {
          // console.log("logout", res);
          userLogoutApi().then(() => {
            localStorage.removeItem(
              process.env.VUE_APP_LOCALSTORAGE_SESSION_ID
            );
            this.$router.push({ name: "Y1W000" });
            this.stopLoading();
          });
        },
      });
    },
  },
};
