var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-text-area",class:[
    _vm.size,
    {
      'is-focus': _vm.isFocus,
      'is-error': _vm.isError,
      'is-disabled': _vm.disabled,
    },
  ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"textarea",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength},domProps:{"value":(_vm.localValue)},on:{"focus":_vm.textareaFocus,"blur":_vm.textareaBlur,"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }