<template>
  <div class="electricitycosts-list">
    <div class="heading">
      <div class="date">{{ label.received_datetime }}</div>
      <div class="wrap">
        <div class="cost">{{ label.electricity_costs }}</div>
        <div class="range">{{ label.running_distance }}</div>
        <div class="speed">{{ label.average_speed }}</div>
      </div>
    </div>
    <div v-if="items.length > 0" class="cost-list">
      <ElectricityCostsItem
        v-for="(item, key) in items"
        :key="key"
        :date="item.received_datetime"
        :cost="item.electricity_costs"
        :range="item.running_distance"
        :speed="item.average_speed"
        :cost-unit="item.electricity_costs_unit"
        :range-unit="item.running_distance_unit"
        :speed-unit="item.average_speed_unit"
      />
    </div>
    <p v-if="items.length === 0" class="text">
      {{ notFound }}
    </p>
  </div>
</template>

<script>
import ElectricityCostsItem from "@/components/electricitycosts/ElectricityCostsItem.vue";
export default {
  name: "ElectricityCostsList",
  components: {
    ElectricityCostsItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: Object,
      default: () => {},
    },
    notFound: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.electricitycosts-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  > .heading {
    display: flex;
    align-items: center;
    font: $sans-normal-14-bold;
    color: $white100;
    min-height: 54px;
    box-sizing: border-box;
    padding-left: $spacing-sm;
    padding-right: $spacing-sm;
    letter-spacing: 0.01em;

    > .date,
    > .wrap > .cost,
    .range,
    .speed {
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    > .date {
      width: 60px;
    }

    > .wrap {
      display: flex;
      width: calc(100% - 60px);
    }

    > .wrap > .cost,
    .range,
    .speed {
      width: calc(100% / 3);
    }
  }

  > .cost-list {
    overflow: scroll;
    padding-bottom: $spacing-xs;
  }

  > .cost-list > .electricitycosts-item + .electricitycosts-item {
    border-top: none;
  }

  > .text {
    font: $sans-none-16;
    color: $white100;
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
    padding-left: $spacing-md;
    padding-right: $spacing-md;
    word-wrap: break-word;
  }
}
</style>
