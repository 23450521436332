<template>
  <div class="icon-wrap">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6281 5H7.8452L4 23H23.4699L19.6281 5ZM9.01156 6.45453H13.0153V8.59796H14.4596V6.45453H18.4634L21.688 21.5438H5.78704L9.01156 6.45453Z"
        fill="white"
      />
      <path
        d="M14.4578 11.5122H13.0135V14.4247H14.4578V11.5122Z"
        fill="white"
      />
      <path
        d="M14.4578 17.3303H13.0135V20.2428H14.4578V17.3303Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconLastTrip",
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 28px;
  height: 28px;
}
</style>
