<template>
  <div class="bottom-navigation">
    <div class="iconwrap">
      <a @click="clickNavi('Y1W020', ACTION.NAVIGATION.CLICK_BUTTON_HOME)">
        <IconHome :is-focus="this.$route.name === 'Y1W020'" />
      </a>
      <a
        @click="
          clickNavi('Y1W035', ACTION.NAVIGATION.CLICK_BUTTON_TRAVEL_HISTORY)
        "
      >
        <IconTrajectory
          :is-focus="
            this.$route.name === 'Y1W035' || this.$route.name === 'Y1W030'
          "
        />
      </a>
      <a
        @click="
          clickNavi('Y1W040', ACTION.NAVIGATION.CLICK_BUTTON_PARKING_POSITION)
        "
      >
        <IconLocationNav :is-focus="this.$route.name === 'Y1W040'" />
      </a>
      <a
        @click="
          clickNavi('Y1W050', ACTION.NAVIGATION.CLICK_BUTTON_ELECTRIC_MILEAGE)
        "
      >
        <IconGraph :is-focus="this.$route.name === 'Y1W050'" />
      </a>
    </div>
  </div>
</template>

<script>
import IconHome from "@/components/icon/IconHome.vue";
import IconTrajectory from "@/components/icon/IconTrajectory.vue";
import IconLocationNav from "@/components/icon/IconLocationNav.vue";
import IconGraph from "@/components/icon/IconGraph.vue";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "BottomNavigation",
  components: {
    IconHome,
    IconTrajectory,
    IconLocationNav,
    IconGraph,
  },
  data() {
    return {
      ACTION: ACTION,
    };
  },
  methods: {
    async clickNavi(routeName, action) {
      // 同画面更新の場合はリロードするため、イベント送信完了まで待機
      await sendEvent(SCREEN_ID.NAVIGATION, action);
      if (this.$route.name === routeName) {
        location.href = this.$route.path;
      } else {
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-navigation {
  background-color: $primary700;
  width: 100%;
  height: 80px;

  > .iconwrap {
    display: flex;
    justify-content: space-around;
    width: 311px;
    height: 40px;
    margin: 0 auto;
    padding-top: $spacing-xs;
  }

  > .iconwrap > a {
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
