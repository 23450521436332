<template>
  <Layout
    :title="text.page_title"
    :show-back-button="true"
    class="notice-detail"
    @click="onReturn"
  >
    <div class="container">
      <p class="notice-detail__title">
        {{ notification.notification_title }}
      </p>
      <p class="notice-detail__date">
        {{
          formatted(
            text.notification_send_tiemstamp,
            notification.notification_timestamp
          )
        }}
      </p>
      <p class="notice-detail__text">{{ notification.notification_text }}</p>
      <p class="notice-detail__detection">
        {{
          formatted(
            text.notification_detection_timestamp,
            notification.notification_detection_timestamp
          )
        }}
      </p>
      <p class="notice-detail__vin">
        {{ formatted(text.vin, notification.vin) }}
      </p>
    </div>
  </Layout>
</template>

<script>
import { notificationDetailApi } from "@/utils/ApiHelper";
import Layout from "@/components/layout/Layout";
import moment from "moment";
import { mapActions } from "vuex";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W105",
  components: {
    Layout,
  },
  mixins: [ApiMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!from.name) vm.isLandingPage = true;
    });
  },
  data() {
    return {
      isLandingPage: false,
      text: {},
      notification: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      const date = new Date();
      this.$_verifyGigyaLogin(() => {
        notificationDetailApi({
          id: this.$route.params.id,
          timezone_diff_min: date.getTimezoneOffset(),
        }).then((res) => {
          this.text = res.data.text;
          this.notification = res.data.item.notification;
          this.stopLoading();
        });
      });
    },
    onReturn() {
      sendEvent(
        SCREEN_ID.NAVIGATION,
        ACTION.NAVIGATION.CLICK_BUTTON_BACKTO_NOTICE_DETAIL
      );
      if (this.isLandingPage) {
        this.$router.push({
          name: "Y1W100",
          query: {
            year: moment(
              this.notification.notification_timestamp,
              "ddd, DD MMM YYYY HH:mm"
            )
              .year()
              .toString(),
            page: 1,
          },
        });
        return;
      }
      this.$router.go(-1);
    },
    formatted(label, value) {
      return `${label} : ${value}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-detail {
  .container {
    padding: $spacing-md;

    p {
      color: $white100;
    }
  }

  &__title {
    font: $sans-tight-16;
    margin-bottom: $spacing-xxxs;
    word-wrap: break-word;
  }

  &__date {
    font: $sans-none-12;
    margin-bottom: $spacing-sm;
  }

  &__text {
    font: $sans-tight-14;
    word-wrap: break-word;
    white-space: pre-wrap;
    margin-bottom: $spacing-sm;
  }

  &__detection {
    font: $sans-tight-14;
  }

  &__vin {
    font: $sans-tight-14;
  }
}
</style>
