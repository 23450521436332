<template>
  <div class="icon-wrap">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1667 3.5C9.36832 3.5 4.66666 8.20167 4.66666 14H1.16666L5.70499 18.5383L5.78666 18.7017L10.5 14H6.99999C6.99999 9.485 10.6517 5.83333 15.1667 5.83333C19.6817 5.83333 23.3333 9.485 23.3333 14C23.3333 18.515 19.6817 22.1667 15.1667 22.1667C12.915 22.1667 10.8733 21.245 9.40332 19.7633L7.74666 21.42C9.64832 23.3217 12.2617 24.5 15.1667 24.5C20.965 24.5 25.6667 19.7983 25.6667 14C25.6667 8.20167 20.965 3.5 15.1667 3.5ZM14 9.33333V15.1667L18.9933 18.13L19.8333 16.7183L15.75 14.2917V9.33333H14Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconRunningTime",
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 28px;
  height: 28px;
}
</style>
