<template>
  <div class="list-history">
    <p v-if="isEmpty" class="message">{{ emptyMessage }}</p>
    <template v-else>
      <ul class="list">
        <li class="list-item header">
          <div class="datetime">{{ header.received_datetime }}</div>
          <div class="shrink">
            <div class="running-time">
              {{ header.running_time }}
            </div>
            <div class="running-distance">
              {{ header.running_distance }}
            </div>
          </div>
        </li>
        <li
          v-for="item of items"
          :key="item.id"
          class="list-item"
          @click="$emit('onClick', item.id)"
        >
          <time class="datetime">{{ item.received_datetime }}</time>
          <div class="shrink">
            <div class="running-time">
              {{ item.running_time }}{{ item.running_time_unit }}
            </div>
            <div class="running-distance">
              {{ item.running_distance }}{{ item.running_distance_unit }}
            </div>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "ListHistory",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Object,
      default: () => {},
    },
    emptyMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    isEmpty() {
      return this.items.length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-history {
  > .message {
    color: $white100;
    font: $sans-tight-16;
    margin: 0 $spacing-md;
    word-wrap: break-word;
  }

  > .list {
    > .list-item {
      height: 56px;
      box-sizing: border-box;
      border-top: 1px solid $black200;
      padding-left: $spacing-sm;
      padding-right: $spacing-sm;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $white100;
      font: $sans-none-14;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid $black200;
      }

      &.header {
        height: 54px;
        border-top: none;
        padding-top: $spacing-sm;
        padding-bottom: $spacing-xs;
        position: sticky;
        position: -webkit-sticky;
        top: 128px;
        background-color: #121212;

        > .datetime {
          font: $sans-normal-14-bold;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        > .shrink {
          > .running-time,
          > .running-distance {
            font: $sans-normal-14-bold;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      > .datetime {
        width: 140px;
        min-width: 140px;
      }

      > .shrink {
        width: 100%;
        width: calc(100% - 140px);
        display: flex;
        align-items: center;

        > .running-time,
        > .running-distance {
          width: 50%;
          text-align: right;
        }
      }
    }
  }
}
</style>
