<template>
  <div class="icon-wrap">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.85551 18.13C8.69551 18.13 9.98551 16.66 9.98551 14.31C9.98551 11.96 8.69551 10.53 6.85551 10.53C5.01551 10.53 3.72551 11.96 3.72551 14.31C3.72551 16.66 5.01551 18.13 6.85551 18.13ZM6.85551 17.32C5.53551 17.32 4.67551 16.14 4.67551 14.31C4.67551 12.47 5.53551 11.34 6.85551 11.34C8.16551 11.34 9.03551 12.47 9.03551 14.31C9.03551 16.14 8.16551 17.32 6.85551 17.32ZM11.5674 18H13.4474C15.6574 18 16.8674 16.63 16.8674 14.31C16.8674 11.98 15.6574 10.66 13.4074 10.66H11.5674V18ZM12.4974 17.24V11.42H13.3274C15.0574 11.42 15.9074 12.45 15.9074 14.31C15.9074 16.16 15.0574 17.24 13.3274 17.24H12.4974ZM21.1426 18.13C22.9826 18.13 24.2726 16.66 24.2726 14.31C24.2726 11.96 22.9826 10.53 21.1426 10.53C19.3026 10.53 18.0126 11.96 18.0126 14.31C18.0126 16.66 19.3026 18.13 21.1426 18.13ZM21.1426 17.32C19.8226 17.32 18.9626 16.14 18.9626 14.31C18.9626 12.47 19.8226 11.34 21.1426 11.34C22.4526 11.34 23.3226 12.47 23.3226 14.31C23.3226 16.14 22.4526 17.32 21.1426 17.32Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconODO",
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 28px;
  height: 28px;
}
</style>
