<template>
  <div class="page">
    <div id="gigya"></div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import { userLoginApi } from "@/utils/ApiHelper";
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/layout/Loading.vue";

export default {
  name: "Y1W000",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  mounted() {
    let language = window.navigator.language.toLowerCase();
    if (language !== "zh-tw") {
      language = language.substr(0, 2);
    }

    window.gigya.accounts.showScreenSet({
      screenSet: "Standard-3.0-RegistrationLogin",
      containerID: "gigya",
      lang: language,
      deviceType: "auto",
      sessionExpiration: -2,
    });

    if (!window.isAddLoginEvent) {
      window.isAddLoginEvent = true;
      window.gigya.accounts.addEventHandlers({
        onLogin: (eventObj) => {
          this.startLoading();
          const date = new Date();
          userLoginApi({
            uid: eventObj.UID,
            uidSignature: eventObj.UIDSignature,
            signatureTimestamp: eventObj.signatureTimestamp,
            language_name: language,
            timezone_diff_min: date.getTimezoneOffset(),
          }).then((res) => {
            localStorage.setItem(
              process.env.VUE_APP_LOCALSTORAGE_SESSION_ID,
              res.data.item.session_id
            );
            this.$router.push({ name: "Y1W020" });
          });
        },
      });
    }
  },
  methods: {
    ...mapActions(["startLoading"]),
  },
};
</script>

<style lang="scss">
// ログイン画面とユーザー情報画面に適用される
.gigya-screen {
  input {
    &.gigya-input-text,
    &.gigya-input-password {
      font-size: 16px !important;
    }
  }
}
@media screen and (max-width: 499px) {
  .gigya-screen.v2 {
    width: 100% !important;
  }
}
</style>
