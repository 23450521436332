<template>
  <div>
    <ul class="yearmonth" :class="{ 'is-month': isMonthly }">
      <li class="prev">
        <a v-if="hasPrev" @click="onPrev">
          <IconArrowLeftOrange />
        </a>
      </li>
      <li class="year">
        <div>{{ label }}</div>
      </li>
      <li class="next">
        <a v-if="hasNext" @click="onNext">
          <IconArrowRightOrange />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import IconArrowLeftOrange from "@/components/icon/IconArrowLeftOrange.vue";
import IconArrowRightOrange from "@/components/icon/IconArrowRightOrange.vue";
import moment from "moment";

export default {
  name: "YearMonth",
  components: {
    IconArrowLeftOrange,
    IconArrowRightOrange,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isMonthly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    label() {
      return this.localValue.format(this.format);
    },
    date() {
      return moment(this.localValue, this.format);
    },
    format() {
      return this.isMonthly ? "MMM YYYY" : "YYYY";
    },
    manipulate() {
      return this.isMonthly ? "months" : "years";
    },
    minDate() {
      return moment(process.env.VUE_APP_REFERENCE_FROM_YEAR, "YYYY");
    },
    maxDate() {
      return moment().endOf("month");
    },
    hasPrev() {
      const unit = this.isMonthly ? "month" : "year";
      return this.localValue.isAfter(this.minDate, unit);
    },
    hasNext() {
      const unit = this.isMonthly ? "month" : "year";
      return this.localValue.isBefore(this.maxDate, unit);
    },
  },
  methods: {
    onPrev() {
      this.localValue = this.date.subtract(1, this.manipulate);
      this.$emit("onPrev");
    },
    onNext() {
      this.localValue = this.date.add(1, this.manipulate);
      this.$emit("onNext");
    },
  },
};
</script>

<style lang="scss" scoped>
.yearmonth {
  position: relative;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;

  > .prev {
    position: absolute;
    left: $spacing-xxxs;
    cursor: pointer;
  }

  > .next {
    position: absolute;
    right: $spacing-xxxs;
    cursor: pointer;
  }

  > .year {
    > div {
      font: $sans-none-14;
      color: $white100;
    }
  }

  &.is-month {
    width: 139px;

    @media screen and (max-width: 374px) {
      width: 112px;
    }
  }
}
</style>
