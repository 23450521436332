<template>
  <div class="icon-wrap">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.64511 5.19128C8.59067 3.76707 10.9393 2.99955 13.3504 3C15.7619 3.00032 18.1106 3.76863 20.0561 5.19355C22.0016 6.61847 23.4427 8.62594 24.1706 10.925C24.8985 13.224 24.8752 15.6951 24.1043 17.98C23.3333 20.2649 21.8547 22.245 19.8828 23.6331L19.3593 23.9995L18.6953 23.5036C17.0846 22.423 15.184 21.8563 13.2444 21.8784C11.3049 21.9006 9.41763 22.5104 7.83194 23.6276L7.32225 23.9857L6.81528 23.6276C4.84376 22.2395 3.36559 20.2596 2.59507 17.9749C1.82454 15.6902 1.80174 13.2194 2.52995 10.9209C3.25817 8.62235 4.69954 6.61549 6.64511 5.19128ZM19.3511 21.8285C20.8567 20.6146 21.9626 18.976 22.525 17.1255L20.6488 16.7646L20.9683 15.0288L22.8804 15.3843C23.107 13.3343 22.6643 11.266 21.6186 9.48831L20.0371 10.6454L18.9957 9.22381L20.5881 8.0584C18.9703 6.19008 16.6929 5.01933 14.2321 4.79083V6.71941H12.4688V4.79083C9.973 5.02286 7.6675 6.22359 6.04663 8.13554L7.53164 9.23759L6.4902 10.6592L5.03273 9.593C4.02231 11.338 3.59746 13.3609 3.82047 15.365L5.55897 15.0426L5.87857 16.7783L4.16762 17.0952C4.72735 18.9589 5.83877 20.6092 7.35531 21.8285C9.15541 20.7085 11.2331 20.1149 13.3532 20.1149C15.4733 20.1149 17.551 20.7085 19.3511 21.8285ZM13.0088 12.4556C13.1213 12.4334 13.2357 12.4223 13.3504 12.4225C13.7319 12.4225 14.1047 12.5356 14.4219 12.7475C14.739 12.9595 14.9862 13.2607 15.1322 13.6131C15.2782 13.9655 15.3164 14.3532 15.2419 14.7273C15.1675 15.1014 14.9838 15.4451 14.7141 15.7148C14.4444 15.9845 14.1008 16.1682 13.7267 16.2426C13.3526 16.317 12.9648 16.2788 12.6124 16.1329C12.26 15.9869 11.9588 15.7397 11.7469 15.4226C11.5349 15.1054 11.4218 14.7325 11.4218 14.3511C11.4227 14.2065 11.4394 14.0623 11.4714 13.9213L7.10181 8.35596L13.0088 12.4556Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconAverageSpeed",
};
</script>

<style lang="scss" scoped>
.icon-wrap {
  width: 28px;
  height: 28px;
}
</style>
