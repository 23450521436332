<template>
  <Layout :title="pageTitle">
    <List class="unit-list">
      <ListItem>
        <div class="setting">
          <div class="left">{{ unitLabel }}</div>
          <div class="right">
            <FormSelect
              v-model="item.unit"
              size="small"
              :options="unitOptions"
              @change="changeUnit"
            />
          </div>
        </div>
      </ListItem>
      <ListItem>
        <div class="setting">
          <div class="left">{{ punctuationLabel }}</div>
          <div class="right">
            <FormSelect
              v-model="item.punctuation"
              size="small"
              :options="punctuationOptions"
              @change="changePunctuation"
            />
          </div>
        </div>
      </ListItem>
    </List>
  </Layout>
</template>

<script>
import {
  settingsFormatApi,
  settingsUnitApi,
  settingsNumericruleApi,
} from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import List from "@/components/list/List.vue";
import ListItem from "@/components/list/ListItem.vue";
import FormSelect from "@/components/form/FormSelect";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W120",
  components: {
    Layout,
    List,
    ListItem,
    FormSelect,
  },
  mixins: [ApiMixin],
  data() {
    return {
      item: {
        unit: "",
        punctuation: "",
      },
      pageTitle: "",
      unitLabel: "",
      unitOptions: [],
      punctuationLabel: "",
      punctuationOptions: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        settingsFormatApi().then((res) => {
          this.item = res.data.item;
          this.pageTitle = res.data.text.page_title;
          this.unitLabel = res.data.text.unit.label;
          this.unitOptions = res.data.text.unit.options;
          this.punctuationLabel = res.data.text.punctuation.label;
          this.punctuationOptions = res.data.text.punctuation.options;
          this.stopLoading();
        });
      });
    },
    changeUnit() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        settingsUnitApi({ unit: this.item.unit }).then(() =>
          this.stopLoading()
        );
      });
      if (this.item.unit === "0001") {
        sendEvent(SCREEN_ID.Y1W120, ACTION.Y1W120.SELECT_KM);
      } else if (this.item.unit === "0002") {
        sendEvent(SCREEN_ID.Y1W120, ACTION.Y1W120.SELECT_MILE);
      }
    },
    changePunctuation() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        settingsNumericruleApi({ punctuation: this.item.punctuation }).then(
          () => this.stopLoading()
        );
      });
      if (this.item.punctuation === "0001") {
        sendEvent(SCREEN_ID.Y1W120, ACTION.Y1W120.SELECT_PERIOD);
      } else if (this.item.punctuation === "0002") {
        sendEvent(SCREEN_ID.Y1W120, ACTION.Y1W120.SELECT_COMMA);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unit-list {
  .list-item {
    border-top: none;
  }
}
</style>
