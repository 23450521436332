<template>
  <button
    class="button"
    :class="[
      type,
      size,
      color,
      {
        'is-disabled': disabled,
      },
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <span class="label">
      <slot></slot>
    </span>
    <IconArrowRight v-if="type === 'arrow'" class="button-icon" />
  </button>
</template>

<script>
import IconArrowRight from "@/components/icon/IconArrowRight";

export default {
  name: "Button",
  components: {
    IconArrowRight,
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "default",
    },
    color: {
      type: String,
      default: "white",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius;
  width: 100%;
  box-sizing: border-box;

  &.default {
    font: $sans-normal-16;
    min-width: 180px;
    max-width: 180px;
    height: $button-height;
  }

  &.small {
    font: $sans-normal-12;
    min-width: 110px;
    max-width: 110px;
    height: 32px;
  }

  &.medium {
    font: $sans-normal-16;
    min-width: 152px;
    max-width: 152px;
    height: 40px;
  }

  &.white {
    color: $white100;
  }

  &.black {
    color: $black900;
  }

  &.primary {
    border: none;
    background-color: $primary300;

    &.is-disabled {
      background-color: $black100;
    }
  }

  &.normal {
    border: 1px solid $white100;
    background-color: transparent;

    &.black {
      border-color: $primary200;

      &:hover {
        border-color: $primary400;
      }
    }

    &.is-disabled {
      border: 1px solid $black100;
      color: $black100;
    }
  }

  &.arrow {
    position: relative;
    border: 1px solid $white100;
    background-color: transparent;

    &.black {
      border-color: $primary200;

      .button-icon {
        ::v-deep {
          path {
            fill: $primary200;
          }
        }
      }

      &:hover {
        border-color: $primary400;

        .button-icon {
          ::v-deep {
            path {
              fill: $primary400;
            }
          }
        }
      }
    }

    &:hover {
      border-color: $primary200;

      .button-icon {
        ::v-deep {
          path {
            fill: $primary200;
          }
        }
      }
    }

    &.is-disabled {
      border: 1px solid $black100;
      color: $black100;

      .button-icon {
        ::v-deep {
          path {
            fill: $black100;
          }
        }
      }
    }

    &.small {
      .button-icon {
        right: $spacing-xxs;
        width: 6px;
      }
    }
  }

  &.cancel {
    border: none;
    color: $black900;

    &:hover {
      background-color: $gray200;
      color: $primary200;
    }

    &.is-disabled {
      color: $black100;
      &:hover {
        background: none;
      }
    }
  }

  .button-icon {
    position: absolute;
    right: $spacing-xs;
  }

  .label {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
