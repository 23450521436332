<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.02572 26.4328L8.47608 23.9181L8.38807 23.8321C7.01973 22.4658 6.04465 20.7562 5.56535 18.8829H2.66663V13.5496H5.56535C5.69902 13.0295 5.87088 12.52 6.07949 12.0252C6.62002 10.7449 7.40407 9.58171 8.38807 8.60033L8.47608 8.51431L7.02572 5.99968L11.6449 3.33301L13.0952 5.84364C14.9984 5.31554 17.0095 5.31554 18.9127 5.84364L20.3611 3.33301L24.9802 5.99968L23.5318 8.51431L23.6199 8.60033C24.6048 9.58096 25.3902 10.7434 25.9324 12.0232C26.1405 12.5182 26.3123 13.0277 26.4466 13.5476H29.3333V18.8829H26.4346C26.3006 19.4029 26.1288 19.9124 25.9204 20.4073C25.3804 21.6873 24.5963 22.8498 23.6119 23.8301C23.5819 23.8601 23.5538 23.8901 23.5238 23.9181L24.9742 26.4328L20.3551 29.0994L18.9067 26.5888C17.0028 27.117 14.9911 27.117 13.0872 26.5888L11.6389 29.0994L7.02572 26.4328ZM13.9655 11.393C12.7674 11.8983 11.7999 12.8307 11.2506 14.0092C10.7013 15.1877 10.6095 16.5283 10.993 17.7707C11.3765 19.0131 12.2079 20.0687 13.3259 20.7325C14.4438 21.3964 15.7686 21.6213 17.043 21.3634C18.3174 21.1055 19.4505 20.3833 20.2224 19.337C20.9943 18.2907 21.3499 16.9949 21.2202 15.7011C21.0905 14.4074 20.4847 13.208 19.5204 12.3357C18.5562 11.4635 17.3022 10.9807 16.002 10.9809C15.3024 10.9805 14.6098 11.1206 13.9655 11.393Z"
      fill="#9B9B9B"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMenu",
};
</script>
