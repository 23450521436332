<template>
  <div ref="map" class="map-base"></div>
</template>

<script>
export default {
  name: "MapBase",
  props: {
    language: {
      type: String,
      required: true,
      default: "",
    },
    center: {
      type: Object,
      required: true,
      default: () => {},
    },
    zoom: {
      type: Number,
      required: false,
      default: 17,
    },
  },
  created() {
    this.loadScript();
    window.initMap = this.initMap;
  },
  destroyed() {
    document.getElementById("map-script").remove();
    window.google = {};
  },
  methods: {
    loadScript() {
      let script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAPS_API_KEY}&language=${this.language}&callback=initMap&libraries=&v=weekly`;
      script.async = true;
      script.setAttribute("id", "map-script");
      document.head.appendChild(script);
    },
    initMap() {
      const map = new window.google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: this.zoom,
        mapTypeControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        keyboardShortcuts: false,
        clickableIcons: false,
        // styles: [
        //   {
        //     elementType: "labels",
        //     stylers: [
        //       {
        //         visibility: "off",
        //       },
        //     ],
        //   },
        //   {
        //     featureType: "administrative.country",
        //     elementType: "labels.text",
        //     stylers: [
        //       {
        //         visibility: "on",
        //       },
        //       {
        //         weight: 5.5,
        //       },
        //     ],
        //   },
        //   {
        //     featureType: "administrative.land_parcel",
        //     stylers: [
        //       {
        //         visibility: "off",
        //       },
        //     ],
        //   },
        //   {
        //     featureType: "administrative.neighborhood",
        //     stylers: [
        //       {
        //         visibility: "off",
        //       },
        //     ],
        //   },
        //   {
        //     featureType: "poi.business",
        //     stylers: [
        //       {
        //         visibility: "off",
        //       },
        //     ],
        //   },
        //   {
        //     featureType: "poi.park",
        //     stylers: [
        //       {
        //         visibility: "simplified",
        //       },
        //     ],
        //   },
        //   {
        //     featureType: "road",
        //     stylers: [
        //       {
        //         visibility: "simplified",
        //       },
        //     ],
        //   },
        //   {
        //     featureType: "transit.station",
        //     elementType: "labels",
        //     stylers: [
        //       {
        //         visibility: "simplified",
        //       },
        //     ],
        //   },
        // ],
      });

      this.$emit("init", map);
    },
  },
};
</script>

<style scoped>
.map-base {
  height: 100%;
  width: 100%;
}
</style>
