<template>
  <button class="button-currentmylocation" @click="onClick">
    <IconMyLocation />
  </button>
</template>

<script>
import IconMyLocation from "@/components/icon/IconMyLocation";

export default {
  name: "ButtonCurrentMyLocation",
  components: {
    IconMyLocation,
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-currentmylocation {
  background: $white100;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0px $spacing-xxxxs $spacing-xxxs rgba($black900, $opacity);
}
</style>
