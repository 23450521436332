<template>
  <div class="loading">
    <div class="loading__position">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black900, 0.5);
  z-index: $loading-z-index;

  .loading__position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .loader {
    width: 64px;
    height: 64px;
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 6px solid $gray300;
    border-right: 6px solid $gray300;
    border-bottom: 6px solid $gray300;
    border-left: 6px solid $yellow300;
    animation: load 1.1s infinite linear;
  }
  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
