<template>
  <IconBase :size="size">
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 4.66667C23.3851 4.66667 18.8738 6.03514 15.0367 8.59904C11.1995 11.1629 8.20883 14.8071 6.44278 19.0707C4.67674 23.3343 4.21466 28.0259 5.11498 32.5521C6.0153 37.0783 8.23759 41.2359 11.5008 44.4992C14.764 47.7624 18.9216 49.9847 23.4479 50.885C27.9741 51.7853 32.6656 51.3232 36.9292 49.5572C41.1929 47.7911 44.837 44.8005 47.4009 40.9633C49.9648 37.1262 51.3333 32.6149 51.3333 28C51.3333 24.9358 50.7298 21.9017 49.5572 19.0707C48.3845 16.2398 46.6658 13.6675 44.4991 11.5008C42.3324 9.33414 39.7602 7.61542 36.9292 6.44281C34.0983 5.2702 31.0641 4.66667 28 4.66667ZM23.3333 39.6667L11.6666 28L14.9566 24.71L23.3333 33.0633L41.0433 15.3533L44.3333 18.6667L23.3333 39.6667Z"
        fill="#FFCA28"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from "./IconBase";

export default {
  name: "IconLinkageDone",
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
