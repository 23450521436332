import { render, staticRenderFns } from "./IconAverageSpeed.vue?vue&type=template&id=51e3b583&scoped=true&"
import script from "./IconAverageSpeed.vue?vue&type=script&lang=js&"
export * from "./IconAverageSpeed.vue?vue&type=script&lang=js&"
import style0 from "./IconAverageSpeed.vue?vue&type=style&index=0&id=51e3b583&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e3b583",
  null
  
)

export default component.exports