<template>
  <div class="icon-base">
    <i class="icon" :style="{ width: `${size}px`, height: `${size}px` }">
      <slot />
    </i>
  </div>
</template>

<script>
export default {
  name: "IconBase",
  props: {
    size: {
      type: Number,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-base {
  display: flex;
  align-items: center;

  .icon {
    > svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
