<template>
  <header
    v-if="hideTitle"
    class="header"
    :class="{ 'is-hideTitle': hideTitle }"
  >
    <DrawerMenu class="header__menu-button" />
  </header>
  <header v-else-if="titleOnly" class="header">
    <Title><slot /></Title>
  </header>
  <header v-else class="header">
    <button v-if="showBackButton" class="header__back-button" @click="goBack">
      <IconArrowLeft />
    </button>
    <Title><slot /></Title>
    <DrawerMenu class="header__menu-button" />
  </header>
</template>

<script>
import Title from "@/components/layout/Title";
import IconArrowLeft from "@/components/icon/IconArrowLeft";
import DrawerMenu from "@/components/drawermenu/DrawerMenu";

export default {
  name: "Header",
  components: {
    Title,
    IconArrowLeft,
    DrawerMenu,
  },
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    titleOnly: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: $primary700;
  box-shadow: 0 $spacing-xxxxs $spacing-xxxs rgba(0, 0, 0, $opacity);
  height: 72px;
  width: 100%;

  &.is-hideTitle {
    background: transparent;
    box-shadow: none;
  }

  &__menu-button {
    ::v-deep .menu-button {
      position: absolute;
      right: $spacing-sm;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      box-sizing: border-box;
      height: 32px;
      width: 32px;
    }
  }

  &__back-button {
    position: absolute;
    left: $spacing-xs;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    box-sizing: border-box;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > .title {
    display: block;
    width: calc(100% - 120px);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
