var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button",class:[
    _vm.type,
    _vm.size,
    _vm.color,
    {
      'is-disabled': _vm.disabled,
    },
  ],attrs:{"disabled":_vm.disabled},on:{"click":_vm.onClick}},[_c('span',{staticClass:"label"},[_vm._t("default")],2),(_vm.type === 'arrow')?_c('IconArrowRight',{staticClass:"button-icon"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }