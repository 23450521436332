<template>
  <Layout :title="text.page_title" class="bike-info">
    <div class="container">
      <FormBlock :label="vinCodeLabel">
        <FormInputText v-model="item.vin_code" :disabled="true" />
      </FormBlock>
      <p class="bike-info__text">{{ text.sentence }}</p>
      <FormBlock :label="numberLabel">
        <FormInputText v-model="item.number" :maxlength="20" />
      </FormBlock>
      <FormBlock :label="bikeNameLabel">
        <FormInputText v-model="item.bike_name" :maxlength="20" />
      </FormBlock>
      <FormBlock :label="memoLabel">
        <FormTextArea v-model="item.memo" placeholder="" :maxlength="500" />
      </FormBlock>
      <ButtonBlock layout="center">
        <Button @click="onRegister">{{ text.register_button }}</Button>
      </ButtonBlock>
    </div>
    <Dialog
      ref="dialog"
      :title="dialogMessage"
      :show-button="false"
      @confirm="$refs.dialog.hideDialog()"
    />
  </Layout>
</template>

<script>
import {
  getSettingsVehicleApi,
  postSettingsVehicleApi,
} from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import FormBlock from "@/components/form/FormBlock";
import FormInputText from "@/components/form/FormInputText";
import FormTextArea from "@/components/form/FormTextArea";
import ButtonBlock from "@/components/button/ButtonBlock";
import Button from "@/components/button/Button";
import Dialog from "@/components/dialog/Dialog";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W080",
  components: {
    Layout,
    FormBlock,
    FormInputText,
    FormTextArea,
    ButtonBlock,
    Button,
    Dialog,
  },
  mixins: [ApiMixin],
  data() {
    return {
      dialogMessage: "",
      item: {},
      text: {},
      vinCodeLabel: "",
      numberLabel: "",
      bikeNameLabel: "",
      memoLabel: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        getSettingsVehicleApi().then((res) => {
          this.item = res.data.item;
          this.text = res.data.text;
          this.vinCodeLabel = res.data.text.vin_code.label;
          this.numberLabel = res.data.text.number.label;
          this.bikeNameLabel = res.data.text.bike_name.label;
          this.memoLabel = res.data.text.memo.label;
          this.stopLoading();
        });
      });
    },
    onRegister() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        postSettingsVehicleApi({
          number: this.item.number,
          bike_name: this.item.bike_name,
          memo: this.item.memo,
          purchase_date: "",
        }).then((res) => {
          this.dialogMessage = res.data.text.dialog.title;
          this.$refs.dialog.openDialog();
          this.stopLoading();
        });
      });
      sendEvent(SCREEN_ID.Y1W080, ACTION.Y1W080.CLICK_BUTTON_REGISTER);
    },
  },
};
</script>

<style lang="scss" scoped>
.bike-info {
  .container {
    padding: $spacing-md;
    > .button-block {
      margin-top: $spacing-md;
    }

    .form-block {
      margin-bottom: $spacing-md;
    }
  }

  &__text {
    font: $sans-tight-14;
    color: $white100;
    margin-bottom: $spacing-sm;
    word-wrap: break-word;
  }
}
</style>
