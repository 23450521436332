<template>
  <div>
    <div class="drawer-menu" :class="{ active: opened }">
      <div v-if="opened" class="drawer-menu__container">
        <button class="drawer-menu__button" @click="closeDrawerMenu">
          <IconMenuClose />
        </button>
        <div v-if="drawerMenu" class="drawer-menu-list">
          <div>
            <Title class="menu-title">
              <p class="menu-title-label">
                {{ drawerMenu.app_setting.heading }}
              </p>
            </Title>
            <ul class="menu-list">
              <DrawerMenuItem
                @onClick="clickMenu('Y1W005', ACTION.DRAWERMENU.LINK_USER_INFO)"
              >
                <p class="menu-item-label">
                  {{ drawerMenu.app_setting.userinfo.label }}
                </p>
              </DrawerMenuItem>
              <DrawerMenuItem
                :disabled="!isLinkaged"
                @onClick="
                  clickMenu(
                    'Y1W100',
                    ACTION.DRAWERMENU.LINK_NOTIFICATION_HISTORY
                  )
                "
              >
                <p class="menu-item-label">
                  {{ drawerMenu.app_setting.notification_list.label }}
                </p>
              </DrawerMenuItem>
              <DrawerMenuItem
                :disabled="!isLinkaged"
                @onClick="
                  clickMenu('Y1W090', ACTION.DRAWERMENU.LINK_NOTICE_SETTING)
                "
              >
                <p class="menu-item-label">
                  {{ drawerMenu.app_setting.notification.label }}
                </p>
              </DrawerMenuItem>
              <DrawerMenuItem
                :disabled="!isLinkaged"
                @onClick="
                  clickMenu(
                    'Y1W120',
                    ACTION.DRAWERMENU.LINK_DISTANCE_UNIT_AND_FORMAT
                  )
                "
              >
                <p class="menu-item-label">
                  {{ drawerMenu.app_setting.format.label }}
                </p>
              </DrawerMenuItem>
              <DrawerMenuItem
                @onClick="clickMenu('Y1W070', ACTION.DRAWERMENU.LINK_LANGUAGE)"
              >
                <p class="menu-item-label">
                  {{ drawerMenu.app_setting.language.label }}
                </p>
              </DrawerMenuItem>
              <DrawerMenuItem>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="drawerMenu.app_setting.faq.url"
                  class="menu-item-label"
                  @click="clickMenuFaq"
                >
                  {{ drawerMenu.app_setting.faq.label }}
                </a>
              </DrawerMenuItem>
              <DrawerMenuItem
                @onClick="clickMenu('Y1W110', ACTION.DRAWERMENU.LINK_CONTACT)"
              >
                <p class="menu-item-label">
                  {{ drawerMenu.app_setting.inquiry.label }}
                </p>
              </DrawerMenuItem>
            </ul>
            <Title class="menu-title">
              <p class="menu-title-label">
                {{ drawerMenu.bike_setting.heading }}
              </p>
            </Title>
            <ul class="menu-list">
              <DrawerMenuItem
                :disabled="!isLinkaged"
                @onClick="
                  clickMenu('Y1W080', ACTION.DRAWERMENU.LINK_MOTORCYCLE_INFO)
                "
              >
                <p class="menu-item-label">
                  {{ drawerMenu.bike_setting.vehicle.label }}
                </p>
              </DrawerMenuItem>
              <DrawerMenuItem
                @onClick="clickMenu('Y1W060', ACTION.DRAWERMENU.LINK_LINKAGE)"
              >
                <p class="menu-item-label">
                  {{ drawerMenu.bike_setting.linkage.label }}
                </p>
              </DrawerMenuItem>
            </ul>
          </div>
        </div>
        <div class="logout">
          <Button v-if="drawerMenu" size="medium" @click="openLogoutDialog">
            {{ drawerMenu.logout_button }}
          </Button>
        </div>
      </div>
    </div>
    <button class="menu-button" @click="openDrawerMenu">
      <IconMenu />
    </button>
    <div
      class="page-overlay"
      :class="{ active: opened }"
      @click="hideDrawerMenu"
      @touchstart="hideDrawerMenu"
    ></div>
    <Dialog
      v-if="drawerMenu"
      ref="logout"
      :title="drawerMenu.logout_dialog.title"
      @confirm="$_logout()"
    />
  </div>
</template>

<script>
import { settingsListApi } from "@/utils/ApiHelper";
import { mapGetters, mapActions } from "vuex";
import IconMenu from "@/components/icon/IconMenu";
import IconMenuClose from "@/components/icon/IconMenuClose";
import Title from "@/components/layout/Title";
import DrawerMenuItem from "@/components/drawermenu/DrawerMenuItem";
import Button from "@/components/button/Button";
import Dialog from "@/components/dialog/Dialog";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "DrawerMenu",
  components: {
    IconMenu,
    IconMenuClose,
    DrawerMenuItem,
    Title,
    Button,
    Dialog,
  },
  mixins: [ApiMixin],
  data() {
    return {
      opened: false,
      ACTION: ACTION,
    };
  },
  computed: {
    ...mapGetters(["drawerMenu", "isLinkaged"]),
  },
  mounted() {
    this.$_verifyGigyaLogin(() => {
      settingsListApi().then((res) => {
        this.setDrawerMenu({ drawerMenu: res.data.text });
      });
    });
  },
  destroyed() {
    document.body.classList.remove("overflowHidden");
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "setDrawerMenu"]),
    openDrawerMenu() {
      this.opened = true;
      document.body.classList.add("overflowHidden");
      sendEvent(SCREEN_ID.NAVIGATION, ACTION.NAVIGATION.CLICK_BUTTON_MENU);
    },
    hideDrawerMenu() {
      this.opened = false;
      document.body.classList.remove("overflowHidden");
    },
    closeDrawerMenu() {
      this.hideDrawerMenu();
      sendEvent(SCREEN_ID.DRAWERMENU, ACTION.DRAWERMENU.CLICK_BUTTON_CLOSE);
    },
    clickMenuFaq() {
      this.hideDrawerMenu();
      sendEvent(SCREEN_ID.DRAWERMENU, ACTION.DRAWERMENU.LINK_FAQ);
    },
    async clickMenu(routeName, action) {
      // 同画面更新の場合はリロードするため、イベント送信完了まで待機
      await sendEvent(SCREEN_ID.DRAWERMENU, action);
      if (this.$route.name === routeName) {
        location.href = this.$route.path;
      } else {
        this.hideDrawerMenu();
        this.$router.push({ name: routeName });
      }
    },
    openLogoutDialog() {
      this.$refs.logout.openDialog();
      sendEvent(SCREEN_ID.DRAWERMENU, ACTION.DRAWERMENU.CLICK_BUTTON_LOGOUT);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  background-color: $black900;
  height: 100%;
  width: 100%;
  z-index: $page-overlay-z-index;

  &.active {
    visibility: visible;
    opacity: 0.5;
  }
}

.drawer-menu {
  position: absolute;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 315px;
  top: 0;
  right: -315px;
  background: $primary700;
  transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
  z-index: $drawer-menu-z-index;
  overflow: scroll;

  &.active {
    right: 0;
  }

  .drawer-menu__container {
    background: $primary700;
    padding-bottom: 150px;

    @include pc {
      padding-bottom: $spacing-md;
    }
  }

  .drawer-menu__button {
    position: absolute;
    right: $spacing-xs;
    top: $spacing-xs;
    padding: 0;
  }

  .logout {
    display: flex;
    justify-content: center;
    margin-top: $spacing-md;
  }

  .drawer-menu-list {
    .menu-title {
      padding: 0 $spacing-md;
      height: 79px;
      box-sizing: border-box;
    }

    .menu-list > .menu-item + .menu-item {
      border-top: none;
    }
  }
}
</style>

<style lang="scss">
body {
  &.overflowHidden {
    overflow: hidden;
  }
}
</style>
