<template>
  <Layout :title="text.page_title">
    <div class="wrap">
      <YearMonth
        v-model="currentDate"
        class="yearmonth-wrap"
        :is-monthly="false"
        @onPrev="changeYearPrev"
        @onNext="changeYearNext"
      />
    </div>
    <ListHistory
      :class="{ exist: histories.length }"
      :items="histories"
      :header="text.list_header"
      :empty-message="text.not_found_error"
      @onClick="toDetailPage"
    />
    <Pagination
      v-if="pageInfo"
      v-model="currentPage"
      :per-page="pageInfo.limit"
      :total-count="pageInfo.count"
      @click="changePage"
    />
  </Layout>
</template>

<script>
import { vehicleHistoryApi } from "@/utils/ApiHelper";
import { mapActions, mapGetters } from "vuex";
import Layout from "@/components/layout/Layout";
import YearMonth from "@/components/yearmonth/YearMonth";
import ListHistory from "@/components/list/ListHistory";
import Pagination from "@/components/pagination/Pagination";
import moment from "moment";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W035",
  components: {
    Layout,
    YearMonth,
    ListHistory,
    Pagination,
  },
  mixins: [ApiMixin],
  data() {
    return {
      currentDate: moment(),
      currentPage: 1,
      histories: [],
      pageInfo: null,
      text: {},
    };
  },
  computed: {
    ...mapGetters(["getPosition"]),
    selectYear() {
      return this.currentDate.format("YYYY");
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
    histories() {
      this.$nextTick(() => {
        if (this.getPosition) {
          scrollTo(this.getPosition.x, this.getPosition.y);
          this.clearPosition();
        }
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading", "clearPosition"]),
    fetchData() {
      this.startLoading();
      if (this.$route.query.year) {
        this.currentDate = moment(this.$route.query.year, "YYYY");
      } else {
        this.currentDate = moment();
      }
      this.currentPage = Number(this.$route.query.page) || 1;
      this.$_verifyGigyaLogin(() => {
        const date = new Date();
        vehicleHistoryApi({
          year: this.currentDate.format("YYYY"),
          page: this.currentPage,
          timezone_diff_min: date.getTimezoneOffset(),
        }).then((res) => {
          this.histories = res.data.item.histories?.items ?? [];
          this.pageInfo = res.data.item.histories?.page_info;
          this.text = res.data.text;
          this.stopLoading();
        });
      });
    },
    changeYearPrev() {
      this.$router.push({
        name: "Y1W035",
        query: { year: this.selectYear, page: 1 },
      });
      sendEvent(SCREEN_ID.Y1W035, ACTION.Y1W035.CLICK_BUTTON_PREVIOUS_YEAR);
    },
    changeYearNext() {
      this.$router.push({
        name: "Y1W035",
        query: { year: this.selectYear, page: 1 },
      });
      sendEvent(SCREEN_ID.Y1W035, ACTION.Y1W035.CLICK_BUTTON_NEXT_YEAR);
    },
    changePage() {
      this.$router.push({
        name: "Y1W035",
        query: { year: this.selectYear, page: this.currentPage },
      });
      sendEvent(SCREEN_ID.Y1W035, ACTION.Y1W035.CLICK_BUTTON_PAGE);
    },
    toDetailPage(historyId) {
      this.$router.push({
        name: "Y1W030",
        params: { id: historyId },
      });
      sendEvent(SCREEN_ID.Y1W035, ACTION.Y1W035.LINK_TRAVEL_HISTORY_DETAIL);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  > .main > .inner > .wrap {
    width: 100%;
    max-width: 768px;
    box-sizing: border-box;
    background-color: $black500;
    padding-top: $spacing-md;
    padding-bottom: $spacing-sm;
    position: fixed;
  }

  .yearmonth-wrap {
    width: 112px;
    margin: 0 auto;
  }

  .list-history {
    padding-top: 80px;

    &.exist {
      padding-top: 56px;
    }
  }

  .pagination-wrap {
    margin: $spacing-sm auto $spacing-md;
  }
}
</style>
