<template>
  <div class="icon-wrap" :class="{ 'is-blinking': isBlinking }">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.7413 16.4786L9.38931 16.4786L9.38931 31.5272L34.7413 31.5272L34.7413 26.8216H38.6107V21.2334H34.7413V16.4786ZM38.1277 13.0058H38.1419L38.1277 13V13.0058ZM6.00001 13.0058L38.1277 13.0058V17.7693H42L42 30.2886H38.1277V35L6.00001 35L6.00001 13.0058ZM21 23.5L14.5 22.5L23 28L23.5 24.5L30.5 26L21.5 19.5L21 23.5Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconLocation",
  props: {
    isBlinking: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-wrap {
  width: 48px;
  height: 48px;

  &.is-blinking {
    animation: flash 2s linear infinite;
  }

  @keyframes flash {
    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
  }
}
</style>
