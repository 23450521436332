<template>
  <div class="icon-arrow-right">
    <svg
      fill="none"
      height="15"
      viewBox="0 0 8 15"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 14.8571 8-7.42853-8-7.42857z" fill="#ffca28" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconArrowRightOrange",
};
</script>

<style>
.icon-arrow-right {
  display: flex;
  align-items: center;
}
</style>
