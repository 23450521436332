import Vue from "vue";
import Vuex from "vuex";
import { LINKAGE_STATUS } from "@/utils/const";

const state = {
  loading: false,
  initial: false,
  position: null,
  drawerMenu: null,
  linkageStatus: 0,
};

const getters = {
  isLoading: (state) => {
    return state.loading;
  },
  isInitialLoading: (state) => {
    return state.initial && state.loading;
  },
  getPosition: (state) => {
    return state.position;
  },
  drawerMenu: (state) => {
    return state.drawerMenu;
  },
  isLinkaged: (state) => {
    return state.linkageStatus === LINKAGE_STATUS.LINKAGED;
  },
};

const mutations = {
  setLoading(state, payload) {
    state.loading = payload.loading;
  },
  setInitial(state, payload) {
    state.initial = payload.initial;
  },
  setPosition(state, payload) {
    state.position = payload.position;
  },
  setDrawerMenu(state, payload) {
    state.drawerMenu = payload.drawerMenu;
  },
  setLinkageStatus(state, payload) {
    state.linkageStatus = payload.linkageStatus;
  },
};

const actions = {
  startLoading({ commit }) {
    commit("setLoading", { loading: true });
  },
  stopLoading({ commit }) {
    commit("setLoading", { loading: false });
    commit("setInitial", { initial: false });
  },
  setInitial({ commit }, payload) {
    commit("setInitial", payload);
  },
  savePosition({ commit }, payload) {
    commit("setPosition", { position: payload });
  },
  clearPosition({ commit }) {
    commit("setPosition", { position: null });
  },
  setDrawerMenu({ commit }, payload) {
    commit("setDrawerMenu", payload);
  },
  setLinkageStatus({ commit }, payload) {
    commit("setLinkageStatus", payload);
  },
};

Vue.use(Vuex);
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
