<template>
  <div class="layout" :style="styleObject">
    <Header
      :hide-title="hideTitle"
      :title-only="titleOnly"
      :show-back-button="showBackButton"
      @click="onReturn"
    >
      {{ title }}
    </Header>
    <div
      class="main"
      :class="[
        { isFullScreen: isFullScreen },
        { hasBottomNavigation: isLinkaged },
      ]"
    >
      <div v-show="!isInitialLoading" class="inner">
        <slot></slot>
      </div>
    </div>
    <BottomNavigation v-if="isLinkaged" />
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/layout/Header.vue";
import BottomNavigation from "@/components/layout/BottomNavigation.vue";
import Loading from "@/components/layout/Loading.vue";

export default {
  name: "Layout",
  components: {
    Header,
    BottomNavigation,
    Loading,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    titleOnly: {
      type: Boolean,
      default: false,
    },
    isHideBottomNavigation: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleObject: {},
    };
  },
  computed: {
    ...mapGetters(["isLoading", "isInitialLoading", "isLinkaged"]),
  },
  mounted() {
    document.body.classList.add("background-color");
    this.setScreenHeight();
    window.addEventListener("resize", this.setScreenHeight);
  },
  destroyed() {
    document.body.classList.remove("background-color");
    window.removeEventListener("resize", this.setScreenHeight);
  },
  methods: {
    onReturn() {
      this.$emit("click");
    },
    setScreenHeight() {
      this.$set(this.styleObject, "height", window.innerHeight + "px");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
  width: 100%;

  > .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layout-z-index;
  }

  > .main {
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 72px;
    background: $black500;

    &.hasBottomNavigation {
      padding-bottom: 80px;
    }

    &.isFullScreen {
      height: 100%;
    }

    > .inner {
      height: 100%;
      width: 100%;
      max-width: 768px;
      margin: 0 auto;
      box-sizing: border-box;
    }
  }

  > .bottom-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $layout-z-index-lower;
  }
}
</style>

<style lang="scss">
html {
  height: 100vh;
}
body {
  height: 100vh;
  &.background-color {
    background-color: $black500;
  }
}
</style>
