<template>
  <Layout :title="text.page_title" class="linkage">
    <!-- リンケージ済 -->
    <div v-if="isLinkaged" class="container">
      <div class="description">
        <p class="description__text spacing-sm">
          {{ text.sentences[0] }}
        </p>
      </div>
      <FormBlock :label="text.vin_code_label">
        <FormInputText v-model="vincode" disabled />
      </FormBlock>
      <ButtonBlock layout="center">
        <Button @click="confirmUnlinkage">{{ text.unlinkage_button }}</Button>
      </ButtonBlock>
    </div>
    <!-- リンケージ済 -->

    <!-- STEP1 -->
    <div v-if="currentStep === 1" class="container">
      <LinkageStepper :step="currentStep" />
      <div class="warning-text">
        <IconWarning :size="24" />
        <p class="text">{{ text.step1.warning }}</p>
      </div>
      <div class="warning-message">
        <p>{{ text.step1.note }}</p>
      </div>
      <div class="step-block">
        <div class="description">
          <p class="description__title">{{ text.step1.heading }}</p>
          <p class="description__text spacing-xs">
            {{ text.step1.sentences[0] }}
          </p>
          <div class="description__image spacing-sm">
            <img
              src="@/assets/images/process01.png"
              alt="yamaha"
              class="steps-image"
            />
          </div>
          <p class="description__text spacing-xs">
            {{ text.step1.sentences[1] }}
          </p>
          <div class="description__image spacing-xs">
            <img
              src="@/assets/images/process02.png"
              alt="yamaha"
              class="steps-image"
            />
          </div>
          <p class="description__text small spacing-md">
            {{ text.step1.sentences[2] }}
          </p>
        </div>
        <ButtonBlock layout="center">
          <Button @click="goToNextStep">{{ text.step1.next_button }}</Button>
        </ButtonBlock>
      </div>
    </div>
    <!-- STEP1 -->

    <!-- STEP2 -->
    <div v-if="currentStep === 2" class="container">
      <LinkageStepper :step="currentStep" />
      <div class="warning-message spacing-md">
        <p>{{ text.step2.note }}</p>
      </div>
      <div class="step-block">
        <div class="description">
          <p class="description__title">{{ text.step2.heading }}</p>
          <p class="description__text spacing-xs">
            {{ text.step2.sentences[0] }}
          </p>
          <div class="description__image spacing-sm">
            <img
              src="@/assets/images/process03.png"
              alt="yamaha"
              class="steps-image"
            />
          </div>
        </div>
        <FormBlock :label="text.step2.vin_code_label" class="linkage-vincode">
          <FormInputText v-model="vincode" :maxlength="25" />
        </FormBlock>
        <ButtonBlock layout="center">
          <Button @click="verifyVinCode">{{ text.step2.next_button }}</Button>
        </ButtonBlock>
      </div>
    </div>
    <!-- STEP2 -->

    <!-- STEP3 -->
    <div v-if="currentStep === 3" class="container">
      <LinkageStepper :step="currentStep" />
      <div class="warning-message spacing-md">
        <p>{{ text.note }}</p>
      </div>
      <div class="step-block">
        <div class="description spacing-sm">
          <p class="description__title">{{ text.heading }}</p>
          <p class="description__text spacing-xs">
            {{ text.sentences[0] }}
          </p>
          <div class="description__image">
            <img
              src="@/assets/images/process04.png"
              alt="yamaha"
              class="steps-image"
            />
          </div>
          <div class="warning-block">
            <div class="wrap">
              <IconWarning :size="24" />
              <p class="text">{{ text.warning_label }}</p>
            </div>
          </div>
          <p class="description__text sans-tight-14-bold spacing-xxxs">
            {{ text.warning }}
          </p>
          <p class="description__text small spacing-xxxs">
            {{ text.sentences[1] }}
          </p>
          <p class="description__text small">
            {{ text.sentences[2] }}
          </p>
        </div>
        <FormBlock
          :label="text.vin_code_label"
          class="linkage-vincode spacing-sm"
        >
          <FormInputText v-model="vincode" disabled />
        </FormBlock>
        <LinkageProgressCircular
          :progress-done="!inProgress"
          class="linkage-progress"
        />
        <ButtonBlock layout="center">
          <Button :disabled="inProgress" @click="goToStep4">{{
            text.next_button
          }}</Button>
        </ButtonBlock>
      </div>
    </div>
    <!-- STEP3 -->

    <!-- STEP4 -->
    <div v-if="currentStep === 4" class="container">
      <LinkageStepper :step="currentStep" />
      <div class="warning-message spacing-md">
        <p>{{ text.note }}</p>
      </div>
      <div class="step-block">
        <div class="description spacing-sm">
          <p class="description__title">{{ text.heading }}</p>
          <p class="description__text spacing-xxxs">
            {{ text.sentences[0] }}
          </p>
          <p class="description__text small">
            {{ text.sentences[1] }}
          </p>
        </div>
        <FormBlock
          :label="text.vin_code_label"
          class="linkage-vincode spacing-sm"
        >
          <FormInputText v-model="vincode" disabled />
        </FormBlock>
        <LinkageProgressCircular
          :progress-done="!inProgress"
          class="linkage-progress"
        />
        <ButtonBlock layout="center">
          <Button :disabled="inProgress" @click="completeStep">
            {{ text.complete_button }}
          </Button>
        </ButtonBlock>
      </div>
    </div>
    <!-- STEP4 -->

    <Dialog
      ref="dialog"
      :title="dialog.title"
      :show-button="dialog.isCancelable"
      :show-text="Boolean(dialog.text)"
      @confirm="dialog.callback"
      @cancel="clickButtonCancel"
    >
      {{ dialog.text }}
    </Dialog>
  </Layout>
</template>

<script>
import {
  getVehicleLinkageApi,
  postVehicleLinkageApi,
  vehicleLinkageVerificationApi,
  vehicleLinkageRemovalApi,
} from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import LinkageStepper from "@/components/linkage/LinkageStepper";
import LinkageProgressCircular from "@/components/linkage/LinkageProgressCircular";
import FormBlock from "@/components/form/FormBlock";
import FormInputText from "@/components/form/FormInputText";
import ButtonBlock from "@/components/button/ButtonBlock";
import Button from "@/components/button/Button";
import Dialog from "@/components/dialog/Dialog";
import IconWarning from "@/components/icon/IconWarning";
import { LINKAGE_STATUS } from "@/utils/const";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W060",
  components: {
    Layout,
    LinkageStepper,
    LinkageProgressCircular,
    FormBlock,
    FormInputText,
    ButtonBlock,
    Button,
    Dialog,
    IconWarning,
  },
  mixins: [ApiMixin],
  data() {
    return {
      linkageStatus: 0,
      vincode: "",
      item: {},
      text: {},
      inProgress: false,
      isNextStep: false,
      intervalId: null,
      dialog: {
        title: "",
        text: "",
        isCancelable: false,
        callback: () => {},
      },
    };
  },
  computed: {
    isLinkaged() {
      return this.linkageStatus === LINKAGE_STATUS.LINKAGED;
    },
    currentStep() {
      let currentStep;
      if (
        this.linkageStatus === LINKAGE_STATUS.UNLINKAGED &&
        !this.isNextStep
      ) {
        currentStep = 1;
      } else if (
        this.linkageStatus === LINKAGE_STATUS.UNLINKAGED &&
        this.isNextStep
      ) {
        currentStep = 2;
      } else if (
        this.linkageStatus === LINKAGE_STATUS.PRELINKAGED_RUN_WAITING
      ) {
        currentStep = 3;
      } else if (
        this.linkageStatus === LINKAGE_STATUS.PRELINKAGED_RUN_RECEIVED
      ) {
        currentStep = 4;
      }
      return currentStep;
    },
  },
  mounted() {
    this.fetchData();
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        getVehicleLinkageApi().then((res) => {
          scrollTo(0, 0);
          this.isNextStep = false;
          this.linkageStatus = res.data.item.linkage_status;
          this.vincode = res.data.item.vin_code;
          this.text = res.data.text;
          this.stopLoading();

          if (this.currentStep === 3 || this.currentStep === 4) {
            this.inProgress = true;
            this.intervalId = setInterval(() => {
              this.$_verifyGigyaLogin(() => {
                vehicleLinkageVerificationApi().then((res) => {
                  if (
                    (this.currentStep === 3 &&
                      res.data.item.linkage_status ===
                        LINKAGE_STATUS.PRELINKAGED_RUN_RECEIVED) ||
                    (this.currentStep === 4 &&
                      res.data.item.linkage_status === LINKAGE_STATUS.LINKAGED)
                  ) {
                    clearInterval(this.intervalId);
                    this.inProgress = false;
                  } else if (
                    res.data.item.linkage_status ===
                    LINKAGE_STATUS.PRELINKAGED_TIMEOUT
                  ) {
                    clearInterval(this.intervalId);
                    this.dialog.title = res.data.text.error_dialog.title;
                    this.dialog.text = res.data.text.error_dialog.text;
                    this.dialog.isCancelable = false;
                    this.dialog.callback = () => {
                      this.$refs.dialog.hideDialog();
                      this.fetchData();
                    };
                    this.$refs.dialog.openDialog();
                  }
                });
              });
            }, 10000);
          }
        });
      });
    },
    goToNextStep() {
      scrollTo(0, 0);
      this.isNextStep = true;
      sendEvent(
        SCREEN_ID.Y1W060_STEP1,
        ACTION.Y1W060_STEP1.CLICK_BUTTON_LINKAGE_STEP2
      );
    },
    verifyVinCode() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        postVehicleLinkageApi({
          vin_code: this.vincode,
        }).then((res) => {
          if (!res.data.metadata.result) {
            this.dialog.title = res.data.text.error_dialog.title;
            this.dialog.text = res.data.text.error_dialog.text;
            this.dialog.isCancelable = false;
            this.dialog.callback = () => {
              this.$refs.dialog.hideDialog();
            };
            this.$refs.dialog.openDialog();
            this.stopLoading();
          } else {
            this.fetchData();
          }
        });
      });
      sendEvent(
        SCREEN_ID.Y1W060_STEP2,
        ACTION.Y1W060_STEP2.CLICK_BUTTON_LINKAGE_STEP3
      );
    },
    goToStep4() {
      this.fetchData();
      sendEvent(
        SCREEN_ID.Y1W060_STEP3,
        ACTION.Y1W060_STEP3.CLICK_BUTTON_LINKAGE_STEP4
      );
    },
    completeStep() {
      this.$router.push({ name: "Y1W020" });
      sendEvent(
        SCREEN_ID.Y1W060_STEP4,
        ACTION.Y1W060_STEP4.CLICK_BUTTON_LINKAGE_COMPLETED
      );
    },
    confirmUnlinkage() {
      this.dialog.title = this.text.dialog.title;
      this.dialog.text = this.text.dialog.text;
      this.dialog.isCancelable = true;
      this.dialog.callback = this.unlinkage;
      this.$refs.dialog.openDialog();
      sendEvent(
        SCREEN_ID.Y1W060_LINKAGED,
        ACTION.Y1W060_LINKAGED.CLICK_BUTTON_LINKAGE_RELEASE
      );
    },
    unlinkage() {
      this.$refs.dialog.hideDialog();
      sendEvent(
        SCREEN_ID.Y1W060_LINKAGED,
        ACTION.Y1W060_LINKAGED.CLICK_BUTTON_OK
      );
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        vehicleLinkageRemovalApi({
          vin_code: this.vincode,
        }).then((res) => {
          this.dialog.title = res.data.text.dialog.title;
          this.dialog.text = res.data.text.dialog.text;
          this.dialog.isCancelable = false;
          this.dialog.callback = () => {
            this.$refs.dialog.hideDialog();
            this.fetchData();
          };
          this.$refs.dialog.openDialog();
          this.stopLoading();
        });
      });
    },
    clickButtonCancel() {
      sendEvent(
        SCREEN_ID.Y1W060_LINKAGED,
        ACTION.Y1W060_LINKAGED.CLICK_BUTTON_CANCEL
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: $spacing-md;
  > .button-block {
    margin-top: $spacing-md;
  }
}

.linkage {
  .warning-text {
    margin-top: $spacing-md;
    display: flex;

    .text {
      margin-left: $spacing-xxxs;
      color: $white100;
      font: $sans-tight-14-bold;
      word-wrap: break-word;
      min-width: 0;
    }
  }

  .warning-message {
    margin: $spacing-xs 0 $spacing-sm;

    &.spacing-md {
      margin-top: $spacing-md;
    }

    p {
      font: $sans-tight-12;
      color: $white100;
      word-wrap: break-word;
      padding: $spacing-xxxs;
      border: 1px solid $primary200;
      border-radius: $radius;
    }
  }

  .warning-block {
    border: 1px solid $red400;
    border-radius: $radius;
    margin: $spacing-sm 0 $spacing-xs;

    .wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $spacing-xxxxs;
    }

    .text {
      color: $white100;
      font: $sans-none-16-bold;
      word-wrap: break-word;
      min-width: 0;
    }
  }

  .description {
    &.spacing-sm {
      margin-bottom: $spacing-sm;
    }

    &__title {
      font: $sans-none-16-bold;
      color: $white100;
      word-wrap: break-word;
      margin-bottom: $spacing-xs;
    }

    &__text {
      font: $sans-tight-14;
      color: $white100;
      word-wrap: break-word;

      &.spacing-xs {
        margin-bottom: $spacing-xs;
      }

      &.spacing-xxs {
        margin-bottom: $spacing-xxs;
      }

      &.spacing-xxxs {
        margin-bottom: $spacing-xxxs;
      }

      &.spacing-sm {
        margin-bottom: $spacing-sm;
      }

      &.spacing-md {
        margin-bottom: $spacing-md;
      }

      &.sans-tight-14-bold {
        font: $sans-tight-14-bold;
      }

      &.small {
        font: $sans-tight-12;

        &.spacing-xxxs {
          margin-bottom: $spacing-xxxs;
        }
      }
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white100;

      &.spacing-xs {
        margin-bottom: $spacing-xs;
      }

      &.spacing-sm {
        margin-bottom: $spacing-sm;
      }

      &.spacing-md {
        margin-bottom: $spacing-md;
      }

      .steps-image {
        width: 100%;
        max-width: 500px;
      }
    }
  }

  .linkage-vincode {
    margin-bottom: $spacing-md;

    &.spacing-sm {
      margin-bottom: $spacing-sm;
    }
  }

  .linkage-progress {
    margin-bottom: $spacing-md;
  }
}
</style>
