<template>
  <IconBase :size="size">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#9B9B9B" />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from "./IconBase";

export default {
  name: "IconStepperDisable",
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
