<template>
  <div class="list-notification">
    <p v-if="isEmpty" class="message">{{ emptyMessage }}</p>
    <ul v-else class="list">
      <li
        v-for="item of items"
        :key="item.id"
        class="list-item"
        @click="$emit('onClick', item.id)"
      >
        <time class="datetime">{{ item.datetime }}</time>
        <div class="title">{{ item.title }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ListNotification",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    emptyMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    isEmpty() {
      return this.items.length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-notification {
  > .message {
    color: $white100;
    font: $sans-tight-16;
    margin: 0 $spacing-md;
    word-wrap: break-word;
  }

  > .list {
    > .list-item {
      height: 56px;
      box-sizing: border-box;
      border-top: 1px solid $black200;
      padding-left: $spacing-sm;
      padding-right: $spacing-sm;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $white100;
      font: $sans-normal-14;
      cursor: pointer;
      &:last-child {
        border-bottom: 1px solid $black200;
      }

      > .datetime {
        width: 140px;
        min-width: 140px;
      }

      > .title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>
