<template>
  <div class="pagination-wrap">
    <paginate
      v-model="currentPage"
      :page-count="totalPages"
      :click-handler="clickCallback"
      prev-class="prev"
      next-class="next"
      :hide-prev-next="true"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
    >
    </paginate>
    <div class="page">{{ startCount }} - {{ endCount }} / {{ totalCount }}</div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  name: "Pagination",
  components: {
    Paginate,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage);
    },
    startCount() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endCount() {
      let endCount;
      if (this.currentPage * this.perPage > this.totalCount) {
        endCount = this.totalCount;
      } else {
        endCount = this.currentPage * this.perPage;
      }
      return endCount;
    },
  },
  methods: {
    clickCallback: function (pageNum) {
      this.$emit("click", pageNum);
    },
  },
};
</script>

<style lang="scss">
.pagination-wrap {
  max-width: 340px;
  margin: 0 auto;

  > .pagination {
    list-style: none;
    display: flex;
    justify-content: center;

    > li {
      margin-right: $spacing-xxxs;
      border-radius: 4px;

      > a {
        font: $sans-none-14;
        color: $white100;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;

        @media screen and (max-width: 374px) {
          width: 32px;
          height: 32px;
        }

        &:focus {
          outline: none;
        }
      }
    }

    > .next {
      margin-right: 0;
    }

    > .prev > a,
    > .next > a {
      font: $sans-none-12-bold;
    }

    > .disabled > a {
      width: auto;
      font: $sans-none-12;
    }

    > .active {
      background-color: $primary300;
    }
  }

  > .page {
    color: $white100;
    text-align: center;
    font: $sans-none-12;
    margin-top: $spacing-xxs;
  }
}
</style>
