<template>
  <div class="electricitycosts-item">
    <time class="time">{{ date }}</time>
    <div class="wrap">
      <div class="cost">{{ cost }}{{ costUnit }}</div>
      <div class="range">{{ range }} {{ rangeUnit }}</div>
      <div class="speed">{{ speed }} {{ speedUnit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElectricityCostsItem",
  props: {
    date: {
      type: String,
      default: "",
    },
    cost: {
      type: String,
      default: "",
    },
    range: {
      type: String,
      default: "",
    },
    speed: {
      type: String,
      default: "",
    },
    costUnit: {
      type: String,
      default: "",
    },
    rangeUnit: {
      type: String,
      default: "",
    },
    speedUnit: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.electricitycosts-item {
  color: $white100;
  font: $sans-none-14;
  display: flex;
  align-items: center;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
  border-top: 1px solid $black200;
  border-bottom: 1px solid $black200;
  box-sizing: border-box;
  height: 56px;

  .time {
    width: 60px;
    min-width: 60px;
  }

  .wrap {
    display: flex;
    width: calc(100% - 60px);
  }

  .wrap > .range,
  .speed,
  .cost {
    width: calc(100% / 3);
    text-align: right;
  }
}
</style>
