<template>
  <Layout class="electricity-graph" :title="text.page_title">
    <div class="flexbox">
      <div class="electricity-graph__top">
        <div class="electricity-graph__header">
          <YearMonth
            v-model="currentDate"
            :is-monthly="isMonthly"
            @onPrev="getPrevData"
            @onNext="getNextData"
          />
          <div class="chips">
            <Chip
              :label="chipsLabel.monthly"
              :active="isMonthly"
              @onClick="getMonthlyData"
            />
            <Chip
              :label="chipsLabel.annual"
              :active="!isMonthly"
              @onClick="getAnnualData"
            />
          </div>
        </div>
        <ChartLine :chartdata="chartdata" />
      </div>
      <div class="full-width">
        <ElectricityCostsList
          :label="listHeader"
          :items="efficiency.items"
          :not-found="text.not_found_error"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { vehicleEfficiencyApi } from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import YearMonth from "@/components/yearmonth/YearMonth";
import Chip from "@/components/chips/Chip";
import ChartLine from "@/components/graph/ChartLine.vue";
import ElectricityCostsList from "@/components/electricitycosts/ElectricityCostsList.vue";
import moment from "moment";
import ApiMixin from "@/mixins/ApiMixin";
import { sendEvent, SCREEN_ID, ACTION } from "@/utils/TreasureDataHelper";

export default {
  name: "Y1W050",
  components: {
    Layout,
    YearMonth,
    Chip,
    ChartLine,
    ElectricityCostsList,
  },
  mixins: [ApiMixin],
  data() {
    return {
      currentDate: moment(),
      isMonthly: true,
      efficiency: {
        items: [],
        graph: [],
      },
      chartdata: {
        isMonthly: true,
        yearmonth: moment().format("YYYY-MM"),
        data: [],
      },
      text: {},
      listHeader: {},
      chipsLabel: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      const date = new Date();
      let params = {
        year: this.currentDate.format("YYYY"),
        timezone_diff_min: date.getTimezoneOffset(),
      };
      if (this.isMonthly) params.month = this.currentDate.format("M");
      this.$_verifyGigyaLogin(() => {
        vehicleEfficiencyApi(params).then((res) => {
          this.efficiency = res.data.item.efficiency;
          this.chartdata = {
            isMonthly: this.isMonthly,
            yearmonth: this.isMonthly
              ? this.currentDate.format("YYYY-MM")
              : this.currentDate.format("YYYY"),
            data: this.efficiency.graph,
          };
          this.text = res.data.text;
          this.listHeader = res.data.text.list_header;
          this.chipsLabel = res.data.text.chips_label;
          this.stopLoading();
        });
      });
    },
    getPrevData() {
      this.fetchData();
      if (this.isMonthly) {
        sendEvent(SCREEN_ID.Y1W050, ACTION.Y1W050.CLICK_BUTTON_PREVIOUS_MONTH);
      } else {
        sendEvent(SCREEN_ID.Y1W050, ACTION.Y1W050.CLICK_BUTTON_PREVIOUS_YEAR);
      }
    },
    getNextData() {
      this.fetchData();
      if (this.isMonthly) {
        sendEvent(SCREEN_ID.Y1W050, ACTION.Y1W050.CLICK_BUTTON_NEXT_MONTH);
      } else {
        sendEvent(SCREEN_ID.Y1W050, ACTION.Y1W050.CLICK_BUTTON_NEXT_YEAR);
      }
    },
    getMonthlyData() {
      this.isMonthly = true;
      this.fetchData();
      sendEvent(SCREEN_ID.Y1W050, ACTION.Y1W050.CLICK_BUTTON_MONTH);
    },
    getAnnualData() {
      this.isMonthly = false;
      this.fetchData();
      sendEvent(SCREEN_ID.Y1W050, ACTION.Y1W050.CLICK_BUTTON_YEAR);
    },
  },
};
</script>

<style lang="scss" scoped>
.electricity-graph {
  position: relative;

  .flexbox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__top {
    background: linear-gradient(180deg, $primary700 0%, $black900 200%);
    padding: $spacing-md $spacing-xs $spacing-xs;
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: $spacing-xs;

    > .chips {
      display: flex;
      > .chip:first-child {
        margin-right: $spacing-xxs;
        @media screen and (max-width: 374px) {
          margin-right: $spacing-xxxxs;
        }
      }
    }
  }

  ::v-deep .main {
    // 電費一覧の表示領域が３件未満の場合はページ全体をスクロールさせる
    @media screen and (min-height: 630px) {
      height: 100%;
    }
  }

  .full-width {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    padding-left: calc(50vw - 50%);
    padding-right: calc(50vw - 50%);
    background-color: $black500;
    overflow: hidden;
    flex: 1;
  }
}
</style>
