<template>
  <IconBase :size="size">
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.85067 0.00292965C1.0716 0.00292964 1.28403 0.0872621 1.45397 0.247494L9.26289 7.99766L1.45397 15.7563C1.12258 16.0852 0.587257 16.0852 0.255867 15.7563C-0.0755234 15.4274 -0.0755234 14.8961 0.255867 14.5672L6.86668 8.00609L0.255866 1.44502C-0.075524 1.11612 -0.0755241 0.584825 0.255866 0.255927C0.417313 0.0872621 0.629743 0.00292966 0.85067 0.00292965Z"
        fill="white"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from "./IconBase";

export default {
  name: "IconArrowRight",
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
