<template>
  <Layout
    :hide-title="true"
    :is-full-screen="true"
    class="home"
    :class="{ isError: item.hardware_error }"
  >
    <div class="container">
      <MessageError v-if="item.hardware_error" :message="text.hardware_error" />
      <div class="graph-block">
        <BatteryMeter
          :is-charging="Boolean(item.in_charge)"
          :battery="item.battery_level"
          :remaining-time="item.charge_time"
        />
        <RunningInfoBlock :date="receivedDatetime">
          <RunningInfoItem>
            <div class="home-info">
              <div class="heading">
                <IconDrivingDistance />
              </div>
              <div class="range">
                {{ item.estimated_running_distance
                }}<span class="unit">{{
                  item.estimated_running_distance_unit
                }}</span>
              </div>
            </div>
          </RunningInfoItem>
          <RunningInfoItem>
            <div class="home-info">
              <div class="heading">
                <IconLastTrip />
                <p class="text">Last Trip</p>
              </div>
              <div class="range">
                {{ item.one_trip_meter
                }}<span class="unit">{{ item.one_trip_meter_unit }}</span>
              </div>
            </div>
          </RunningInfoItem>
          <RunningInfoItem>
            <div class="home-info">
              <div class="heading">
                <IconODO />
              </div>
              <div class="range">
                {{ item.odo_meter
                }}<span class="unit">{{ item.odo_meter_unit }}</span>
              </div>
            </div>
          </RunningInfoItem>
        </RunningInfoBlock>
      </div>
    </div>
  </Layout>
</template>

<script>
import { homeDashboardApi } from "@/utils/ApiHelper";
import { mapActions } from "vuex";
import Layout from "@/components/layout/Layout";
import BatteryMeter from "@/components/graph/BatteryMeter";
import RunningInfoBlock from "@/components/runninginfo/RunningInfoBlock.vue";
import MessageError from "@/components/message/MessageError.vue";
import RunningInfoItem from "@/components/runninginfo/RunningInfoItem.vue";
import IconDrivingDistance from "@/components/icon/IconDrivingDistance.vue";
import IconLastTrip from "@/components/icon/IconLastTrip.vue";
import IconODO from "@/components/icon/IconODO.vue";
import ApiMixin from "@/mixins/ApiMixin";

export default {
  name: "Y1W020",
  components: {
    Layout,
    BatteryMeter,
    RunningInfoBlock,
    RunningInfoItem,
    MessageError,
    IconDrivingDistance,
    IconLastTrip,
    IconODO,
  },
  mixins: [ApiMixin],
  data() {
    return {
      intervalId: null,
      item: {
        estimated_running_distance: "LOW",
        estimated_running_distance_unit: "km",
        one_trip_meter: "---",
        one_trip_meter_unit: "km",
        odo_meter: "---",
        odo_meter_unit: "km",
      },
      text: {},
    };
  },
  computed: {
    receivedDatetime() {
      return this.item.received_datetime
        ? `Update : ${this.item.received_datetime}`
        : "";
    },
  },
  mounted() {
    this.fetchData();
    this.intervalId = setInterval(() => {
      this.$_verifyGigyaLogin(() => {
        const date = new Date();
        homeDashboardApi({ timezone_diff_min: date.getTimezoneOffset() }).then(
          (res) => {
            this.item = res.data.item;
            this.text = res.data.text;
          }
        );
      });
    }, 60000);
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    ...mapActions(["startLoading", "stopLoading"]),
    fetchData() {
      this.startLoading();
      this.$_verifyGigyaLogin(() => {
        const date = new Date();
        homeDashboardApi({ timezone_diff_min: date.getTimezoneOffset() }).then(
          (res) => {
            this.item = res.data.item;
            this.text = res.data.text;
            this.stopLoading();
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  ::v-deep {
    .main {
      min-height: 565px;
      background: linear-gradient(
        180deg,
        #000000 0%,
        #031640 35.94%,
        #000000 100%
      );
      padding-top: 0;
    }
  }

  &.isError {
    ::v-deep {
      .main {
        min-height: 565px + 184px; // エラーメッセージの高さを追加
      }
    }
  }

  .container {
    background-image: url("../assets/images/home.png");
    background-size: 375px auto;
    background-repeat: no-repeat;
    background-position: center bottom 142px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .message-error {
      margin-bottom: $spacing-md;
    }

    .graph-block {
      width: 100%;
      .runninginfo-block {
        margin-top: $spacing-xxxs;
        margin-bottom: $spacing-xs;
        ::v-deep .date {
          font: $sans-none-16;
        }
      }
    }
  }
}
</style>
